var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { init, browserTracingIntegration, replayCanvasIntegration } from '@sentry/browser';
import { SphereApp } from './sphere_app';
import Router from './router';
import { PLANOGRAM_NAME } from './shared/constants';
import { AppUtils } from './utils/app_utils';
import { WINDOW_ORIENTATION, WindowOrientationSupervisor } from './window-orientation/window-orientation.supervisor';
let sphereApp;
function detectViewportChanges() {
    const vh = window.innerHeight * 0.01;
    const vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
}
function isPortraitOrientation() {
    return window.matchMedia('only screen and (orientation: portrait)').matches;
}
function updateMobileWindowOrientation() {
    WindowOrientationSupervisor.changeWindowOrientation(isPortraitOrientation() ? WINDOW_ORIENTATION.PORTRAIT : null);
}
const replaysSessionSampleRate = (() => {
    switch (SPHERE_ENV) {
        case 'dev':
            return 0.4;
        case 'test':
            return 0.2;
        default:
            return 0.1;
    }
})();
function initSentry() {
    return __awaiter(this, void 0, void 0, function* () {
        // NODE_ENV defaults to production on hosted environments
        if (process.env.NODE_ENV === 'production') {
            const integrations = [browserTracingIntegration()];
            if (SPHERE_ENV === 'dev' || SPHERE_ENV === 'test') {
                try {
                    const { replayIntegration } = yield import('@sentry/replay');
                    integrations.push(replayIntegration({
                        maskAllText: false,
                        blockAllMedia: false
                    }), replayCanvasIntegration());
                }
                catch (e) {
                    console.error('Failed to load Sentry replay integrations:', e);
                }
            }
            init({
                dsn: SENTRY_DSN,
                environment: SPHERE_ENV,
                release: WEB_VERSION,
                integrations,
                tracesSampleRate: 1, // 0..1
                replaysSessionSampleRate,
                replaysOnErrorSampleRate: 1
            });
        }
    });
}
function onSizeChange(callback) {
    let active = true;
    let width = window.innerWidth;
    let height = window.innerHeight;
    let isPortrait = isPortraitOrientation();
    function loop() {
        if (!active) {
            return;
        }
        if (window.innerWidth !== width || window.innerHeight !== height || isPortrait !== isPortraitOrientation()) {
            width = window.innerWidth;
            height = window.innerHeight;
            isPortrait = isPortraitOrientation();
            callback(width, height);
        }
        requestAnimationFrame(loop);
    }
    requestAnimationFrame(loop);
    return () => {
        active = false;
    };
}
function refreshSize() {
    updateMobileWindowOrientation();
    sphereApp.resizeCanvas();
    detectViewportChanges();
}
export function sphereAppInitialise(planogramName = PLANOGRAM_NAME.MAIN) {
    initSentry();
    detectViewportChanges();
    Router.init(`${window.location.origin}`);
    sphereApp = new SphereApp({
        planogramName: AppUtils.getPlanogramName(planogramName),
        debug: document.body.dataset.debug,
        sigmoid: document.body.dataset.sigmoid,
        zoomControls: document.body.dataset.zoom,
        limits: document.body.dataset.limits,
        canvas: document.getElementById('gl-canvas')
    });
    refreshSize();
    onSizeChange(refreshSize);
    if (CI_BUILD_NUMBER) {
        console.log('%c⛏\tJenkins = ' + CI_BUILD_NUMBER, 'background: #000; color: #3d8007');
    }
    console.log('%c🌎\tapp = ' + WEB_VERSION, 'background: #000; color: #3d8007');
}
