import { DIRECTION } from '../shared/constants';
import { Search } from '../search/search';
import { searchEventHandler } from '../custom_event_utils';
import { SEARCH_EVENT_NAMES } from '../event-names';
import { Account } from '../account/account';
import { LayoutSwitcher } from '../components/layout-switcher/layout-switcher';
import { SphereApp } from '../sphere_app';
import { Vector2 } from 'three';
export var KeyCodes;
(function (KeyCodes) {
    KeyCodes["ESC"] = "Escape";
    KeyCodes["LEFT"] = "ArrowLeft";
    KeyCodes["UP"] = "ArrowUp";
    KeyCodes["RIGHT"] = "ArrowRight";
    KeyCodes["BOTTOM"] = "ArrowDown";
    KeyCodes["I"] = "KeyI";
    KeyCodes["L"] = "KeyL";
    KeyCodes["O"] = "KeyO";
    KeyCodes["R"] = "KeyR";
    KeyCodes["S"] = "KeyS";
    KeyCodes["A"] = "KeyA";
})(KeyCodes || (KeyCodes = {}));
const KEYBOARD_ZOOM_FACTOR = 0.965;
export class KeyboardControls {
    constructor(cameraControls, inputHandler, overlay) {
        this.cameraControls = cameraControls;
        this.inputHandler = inputHandler;
        this.overlay = overlay;
        this.onKeyDown = (event) => {
            if (this.inputHandler.isOverlayShowing() ||
                Search.isSearchActive ||
                Account.isAccountActive ||
                LayoutSwitcher.isLayoutActive ||
                SphereApp.isNavigationMenuActive) {
                this.handleInputOnOverlay(event);
                return;
            }
            switch (event.key) {
                case KeyCodes.UP:
                    this.cameraControls.clearAnimations();
                    this.inputHandler.animateCameraToClusterByArrows(DIRECTION.UP);
                    break;
                case KeyCodes.RIGHT:
                    this.cameraControls.clearAnimations();
                    this.inputHandler.animateCameraToClusterByArrows(DIRECTION.RIGHT);
                    break;
                case KeyCodes.BOTTOM:
                    this.cameraControls.clearAnimations();
                    this.inputHandler.animateCameraToClusterByArrows(DIRECTION.DOWN);
                    break;
                case KeyCodes.LEFT:
                    this.cameraControls.clearAnimations();
                    this.inputHandler.animateCameraToClusterByArrows(DIRECTION.LEFT);
                    break;
                case KeyCodes.A:
                    this.inputHandler.navigateToAutoplayClusters();
                    break;
                case KeyCodes.I:
                    this.cameraControls.clearAnimations();
                    this.cameraControls.zoomBy(KEYBOARD_ZOOM_FACTOR);
                    break;
                case KeyCodes.O:
                    this.cameraControls.clearAnimations();
                    this.cameraControls.zoomBy(1 / KEYBOARD_ZOOM_FACTOR);
                    break;
                case KeyCodes.L:
                    this.cameraControls.startRotationAnimation(new Vector2(1, 0));
                    break;
                case KeyCodes.R:
                    this.cameraControls.startRotationAnimation(new Vector2(-1, 0));
                    break;
                case KeyCodes.S:
                    this.cameraControls.stopRotatitionAnimation();
                    this.inputHandler.resetAutoplay();
                    break;
            }
        };
        this.onKeyDown = this.onKeyDown.bind(this);
        window.addEventListener('keydown', this.onKeyDown, false);
    }
    handleInputOnOverlay(event) {
        switch (event.key) {
            case KeyCodes.RIGHT:
                event.stopPropagation();
                this.overlay.handleRightKey();
                break;
            case KeyCodes.LEFT:
                event.stopPropagation();
                this.overlay.handleLeftKey();
                break;
            case KeyCodes.ESC:
                this.overlay.hide(true);
                searchEventHandler.emit(SEARCH_EVENT_NAMES.CLOSE_SEARCH);
                break;
        }
    }
    dispose() {
        window.removeEventListener('keydown', this.onKeyDown, false);
    }
}
