const cameraConfig = {
    Z_OFFSET_FACTOR: 1.3,
    DEFAULT_Z_POSITION: 5,
    NEAR_PLANE: 800,
    FAR_PLANE: 9999999,
    CAMERA_2D_FOV: 75,
    CAMERA_2D_NEAR_PLANE: 1,
    CAMERA_2D_FAR_PLANE: 90000,
    FOV_MIN: 0.8,
    FOV_DEFAULT: 26.68,
    ZOOM_FLATTENING_START: 0.5,
    ZOOM_FLATTENING_END: 0.75,
};
export default cameraConfig;
