const searchParams = new URL(self.location.href).searchParams;
// TODO: find a way to enable debug for web workers
const debug = searchParams.has('debug');
export function isDebug() {
    return debug;
}
export function hasUrlFlag(flag) {
    return searchParams.has(flag);
}
export function hasDebugFlag(flag) {
    return debug && searchParams.has(flag);
}
export const debugLog = debug
    ? (...params) => {
        // eslint-disable-next-line no-console
        console.debug(...params);
    }
    : (..._params) => { };
export const assertTrue = debug
    ? function (condition, error) {
        if (!condition)
            throw new Error(error);
    }
    : function (_condition, _error) { };
export const assertStatement = debug
    ? function (statement, error) {
        if (!statement())
            throw new Error(error);
    }
    : function () { };
export function assertDefined(value, error) {
    assertTrue(value !== undefined, error);
}
export function assertNotNull(value, error) {
    assertTrue(value !== null, error);
}
export function debugParameter(key, defaultValue, parser) {
    if (!debug)
        return defaultValue;
    const value = searchParams.get(key);
    if (value == null)
        return defaultValue;
    else
        return parser(value);
}
export function debugIntParameter(key, defaultValue) {
    return debugParameter(key, defaultValue, parseInt);
}
export function debugFloatPrameter(key, defaultValue) {
    return debugParameter(key, defaultValue, parseFloat);
}
export const unreachable = debug
    ? (_error => { })
    : error => {
        throw new Error(error);
    };
const debugCommands = {};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
self.debug = debugCommands;
export const debugCommand = debug
    ? (key, command) => {
        debugCommands[key] = command;
    }
    : () => { };
