class CameraAnimation {
    constructor(endCallback) {
        this.endCallback = endCallback;
    }
    getAdjustment() {
        const adjustment = this.update();
        if (adjustment === undefined && this.endCallback != undefined) {
            this.endCallback();
        }
        return adjustment;
    }
}
export default CameraAnimation;
