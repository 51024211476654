import { Vector2 } from 'three';
import planogramConfig from 'shared/config/PlanogramConfig';
import CameraAnimation from './CameraAnimation';
const BASE_ROTATION_AMOUNT = 0.0027;
class RotateAnimation extends CameraAnimation {
    static get MAX_ZOOM_FRACTION() {
        return 0.95;
    }
    constructor(sphereCamera) {
        super();
        this.zoomFraction = 0;
        this.direction = new Vector2();
        this.sphereCamera = sphereCamera;
    }
    update() {
        const tiltAndPan = this.direction
            .clone()
            .multiplyScalar(1 - this.zoomFraction)
            .multiplyScalar(BASE_ROTATION_AMOUNT);
        const cameraDistanceFromOrigin = this.sphereCamera.camera.position.length();
        const tiltAdjustmentFactor = planogramConfig.EQUATOR_RADIUS / (cameraDistanceFromOrigin + planogramConfig.EQUATOR_RADIUS);
        const pan = tiltAndPan.x;
        const tilt = tiltAndPan.y * tiltAdjustmentFactor;
        return { pan, tilt };
    }
    addRotation(rotation, zoomFraction) {
        this.direction.add(rotation);
        this.zoomFraction = Math.min(zoomFraction, RotateAnimation.MAX_ZOOM_FRACTION);
    }
    clearRotation() {
        this.zoomFraction = 0;
        this.direction.x = 0;
        this.direction.y = 0;
    }
    isNotRotating() {
        return this.direction.length() === 0;
    }
}
export default RotateAnimation;
