import { EventDispatcher, Vector2 } from 'three';
import { MouseButtons } from 'shared/interfaces/Enums';
import { isLeftMouseButton, isMouseClick, isScrollingTrackPad } from 'shared/utils/InputUtils';
import camera3DEventManager, { Camera3DEvents } from 'shared/eventManager/Camera3DEventManager';
const WHEEL_ZOOM_FACTOR = 0.881;
const TRACKPAD_ZOOM_FACTOR = 0.93;
class CameraMouseControls extends EventDispatcher {
    constructor() {
        super(...arguments);
        this.isDraggingCamera = false;
    }
    init(cameraControls, canvasWrapper) {
        this.cameraControls = cameraControls;
        this.canvasWrapper = canvasWrapper;
        this.setupEventListeners(canvasWrapper);
    }
    setupEventListeners(canvasWrapper) {
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onWheel = this.onWheel.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.addEventListener('mousedown', this.onMouseDown, { capture: false });
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.addEventListener('mousemove', this.onMouseMove, { capture: false });
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.addEventListener('mouseup', this.onMouseUp, { capture: false });
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.addEventListener('wheel', this.onWheel, { passive: false });
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.addEventListener('mouseout', this.onMouseOut, { capture: false });
    }
    onMouseDown(event) {
        this.cameraControls.clearAnimations();
        if (isLeftMouseButton(event)) {
            this.mouseDownEvent = event;
            this.isDraggingCamera = true;
            this.pointerIntersection = this.getSphereIntersection(event);
            camera3DEventManager.trigger(Camera3DEvents.DRAG_CAMERA_START);
        }
    }
    onMouseMove(event) {
        this.dispatchEvent({ type: 'move', point: new Vector2(event.clientX, event.clientY) });
        if (!this.isDraggingCamera || !this.pointerIntersection)
            return;
        const newIntersection = this.getSphereIntersection(event);
        if (!newIntersection)
            return;
        this.dispatchEvent({ type: 'blur' });
        this.cameraControls.move(newIntersection, this.pointerIntersection);
    }
    onMouseUp(event) {
        if (isLeftMouseButton(event)) {
            if (this.mouseDownEvent !== undefined && isMouseClick(this.mouseDownEvent, event))
                this.onMouseClick(event);
            if (this.isDraggingCamera) {
                this.isDraggingCamera = false;
                this.cameraControls.endCameraDrag();
            }
        }
    }
    onMouseClick(event) {
        this.dispatchEvent({
            type: 'click',
            point: new Vector2(event.clientX, event.clientY),
        });
    }
    onMouseOut(event) {
        this.onMouseUp(new MouseEvent('mouseup', {
            buttons: MouseButtons.LEFT,
            clientX: event.clientX,
            clientY: event.clientY,
        }));
    }
    onWheel(event) {
        this.cameraControls.clearAnimations();
        let zoomFactor;
        const hardwareFactor = isScrollingTrackPad(event) ? TRACKPAD_ZOOM_FACTOR : WHEEL_ZOOM_FACTOR;
        if (event.deltaY < 0) {
            zoomFactor = hardwareFactor;
        }
        else if (event.deltaY > 0) {
            zoomFactor = 1 / hardwareFactor;
        }
        if (zoomFactor === undefined)
            return;
        this.dispatchEvent({ type: 'blur' });
        this.cameraControls.zoomIntoPoint(new Vector2(event.offsetX, event.offsetY), zoomFactor);
    }
    getSphereIntersection(pointerEvent) {
        return this.cameraControls.sphereCamera.getMouseIntersection(pointerEvent.offsetX, pointerEvent.offsetY);
    }
    dispose() {
        const canvasWrapper = this.canvasWrapper;
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.removeEventListener('wheel', this.onWheel);
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.removeEventListener('mousedown', this.onMouseDown);
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.removeEventListener('mousemove', this.onMouseMove);
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.removeEventListener('mouseup', this.onMouseUp);
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.removeEventListener('mouseout', this.onMouseOut);
    }
}
export default CameraMouseControls;
