import { assertStatement } from 'shared/utils/debug';
import { floatEq } from 'shared/utils/float-util';
import { registerType } from './serialization.util';
function clamp(value, min, max) {
    return Math.min(max, Math.max(min, value));
}
function farthestFrom(value, options) {
    return options.reduce((farthest, it) => Math.abs(it - value) < Math.abs(farthest - value) ? farthest : it);
}
function positiveModulo(dividend, divisor) {
    return ((dividend % divisor) + divisor) % divisor;
}
export class Range {
    end() {
        return new Arithmetic(this.divisor).normalize(this.start + this.length);
    }
    center() {
        return new Arithmetic(this.divisor).normalize(this.start + this.length * 0.5);
    }
    constructor(divisor) {
        this.divisor = divisor;
        this.start = divisor;
        this.length = 0;
    }
    set(start, length) {
        this.start = start;
        this.length = length;
        return this;
    }
    fromStartEnd(start, end) {
        this.start = start;
        this.length = new Arithmetic(this.divisor).rightDistance(start, end);
        return this;
    }
    copy(r) {
        this.start = r.start;
        this.length = r.length;
        return this;
    }
    clone() {
        return new Range(this.divisor).copy(this);
    }
    negate() {
        this.start = this.end();
        this.length = this.divisor - this.length;
        return this;
    }
    intersects(r) {
        const arithmetic = new Arithmetic(this.divisor);
        return (arithmetic.rightDistance(this.start, r.start) < this.length ||
            arithmetic.rightDistance(r.start, this.start) < r.length);
    }
    contains(x) {
        return new Arithmetic(this.divisor).rightDistance(this.start, x) <= this.length;
    }
    clamp(x) {
        if (this.contains(x))
            return x;
        else
            return this.clampToEdge(x);
    }
    clampToEdge(x) {
        const arithmetic = new Arithmetic(this.divisor);
        if (arithmetic.rightDistance(this.end(), x) < arithmetic.rightDistance(x, this.start))
            return this.end();
        else
            return this.start;
    }
    farthestFrom(x) {
        const arithmetic = new Arithmetic(this.divisor);
        const halfCylinderAway = arithmetic.normalize(x + this.divisor * 0.5);
        if (this.contains(halfCylinderAway))
            return halfCylinderAway;
        if (arithmetic.distance(x, this.start) < arithmetic.distance(x, this.end()))
            return this.end();
        else
            return this.start;
    }
    /** Note: isn't associative */
    static boundingRange(divisor, ranges) {
        const arithmetic = new Arithmetic(divisor);
        const orderedRanges = ranges
            .flatMap(r => {
            if (r.start + r.length < divisor)
                return [r.clone()];
            else
                return [
                    new Range(divisor).set(r.start, divisor - r.start),
                    new Range(divisor).set(0, r.end()),
                ];
        })
            .sort((a, b) => a.start - b.start)
            .reduce((result, r) => {
            if (result.length === 0) {
                result.push(r);
                return result;
            }
            const last = result[result.length - 1];
            if (last.intersects(r))
                // merging like this only works because we've split ranges crossing M
                last.length = Math.max(last.length, arithmetic.rightDistance(last.start, r.end()));
            else
                result.push(r);
            return result;
        }, []);
        function rightDistance(a, b) {
            return Math.max(0, arithmetic.rightDistance(a.start, b.start) - a.length);
        }
        const first = orderedRanges[0];
        const last = orderedRanges[orderedRanges.length - 1];
        let largestDistance = rightDistance(last, first);
        const largestExclusion = new Range(divisor).fromStartEnd(last.end(), first.start);
        for (let i = 0; i < orderedRanges.length - 1; i++) {
            const left = orderedRanges[i];
            const right = orderedRanges[i + 1];
            const distance = rightDistance(left, right);
            if (distance > largestDistance) {
                largestDistance = distance;
                largestExclusion.fromStartEnd(left.end(), right.start);
            }
        }
        return largestExclusion.negate();
    }
}
export class CylinderPoint {
    constructor(divisor) {
        this.divisor = divisor;
    }
    __className() {
        return 'CylinderPoint';
    }
    set(x, y) {
        const arithmetic = new Arithmetic(this.divisor);
        this.x = arithmetic.normalize(x);
        this.y = y;
        return this;
    }
    copy(p) {
        this.x = p.x;
        this.y = p.y;
        return this;
    }
    clone() {
        return new CylinderPoint(this.divisor).copy(this);
    }
    add(v) {
        const arithmetic = new Arithmetic(this.divisor);
        this.x = arithmetic.normalize(this.x + v.x);
        this.y += v.y;
        return this;
    }
    sub(v) {
        const arithmetic = new Arithmetic(this.divisor);
        this.x = arithmetic.normalize(this.x - v.x);
        this.y -= v.y;
        return this;
    }
    divide(v) {
        const arithmetic = new Arithmetic(this.divisor);
        this.x = v.x === 0 ? 0 : arithmetic.normalize(this.x / v.x);
        this.y = v.y === 0 ? 0 : this.x / v.y;
        return this;
    }
    isEqual(v) {
        const arithmetic = new Arithmetic(this.divisor);
        const x1 = arithmetic.normalize(this.x);
        const x2 = arithmetic.normalize(v.x);
        return floatEq(x1, x2) && floatEq(this.y, v.y);
    }
    vectorTo(p, v) {
        const arithmetic = new Arithmetic(this.divisor);
        v.x = arithmetic.signedDistance(this.x, p.x);
        v.y = p.y - this.y;
        return v;
    }
    /** keeps X in [0; M] range  */
    toVector(v) {
        const arithmetic = new Arithmetic(this.divisor);
        v.x = arithmetic.normalize(this.x);
        v.y = this.y;
        return v;
    }
    /** keeps X in [origin.x - M/2; origin.x + M/2]  */
    toVectorAround(v, origin) {
        const arithmetic = new Arithmetic(this.divisor);
        v.x = arithmetic.normalizeAround(this.x, origin.x);
        v.y = this.y;
        return v;
    }
    fromVector(v) {
        this.set(v.x, v.y);
        return this;
    }
    edit(func) {
        func(this);
        return this;
    }
    distance(p) {
        const arithmetic = new Arithmetic(this.divisor);
        const xDistance = arithmetic.distance(this.x, p.x);
        const yDistance = this.y - p.y;
        return Math.sqrt(xDistance * xDistance + yDistance * yDistance);
    }
    toString() {
        return `[CylinderPoint (${this.x}, ${this.y}) mod ${this.divisor}]`;
    }
    get [Symbol.toStringTag]() {
        return this.toString();
    }
}
export class CylinderBox {
    static emptyY() {
        return [+Infinity, -Infinity];
    }
    constructor(divisor) {
        this.divisor = divisor;
        this.x = new Range(divisor);
        this.y = CylinderBox.emptyY();
    }
    start() {
        return new CylinderPoint(this.divisor).set(this.x.start, this.y[0]);
    }
    end() {
        return new CylinderPoint(this.divisor).set(this.x.end(), this.y[1]);
    }
    set(x, y) {
        this.x.copy(x);
        this.y[0] = y[0];
        this.y[1] = y[1];
        return this;
    }
    copy(b) {
        this.x.copy(b.x);
        this.y[0] = b.y[0];
        this.y[1] = b.y[1];
        return this;
    }
    clone() {
        return new CylinderBox(this.divisor).copy(this);
    }
    fromBox2(b) {
        const arithmetic = new Arithmetic(this.divisor);
        this.x.set(arithmetic.normalize(b.min.x), b.max.x - b.min.x);
        [this.y[0], this.y[1]] = [b.min.y, b.max.y];
        return this;
    }
    fromCenterAndSize(center, size) {
        const absSize = { x: Math.abs(size.x), y: Math.abs(size.y) };
        const arithmetic = new Arithmetic(this.divisor);
        this.x.set(arithmetic.normalize(center.x - absSize.x * 0.5), absSize.x);
        this.y[0] = center.y - absSize.y * 0.5;
        this.y[1] = this.y[0] + absSize.y;
        return this;
    }
    fromStartEnd(start, end) {
        this.x.fromStartEnd(start.x, end.x);
        this.y[0] = start.y;
        this.y[1] = end.y;
        return this;
    }
    /** keeps the center X in [-M/2; M/2] range */
    toBox2(b) {
        const arithmetic = new Arithmetic(this.divisor);
        const halfLengthX = this.x.length * 0.5;
        const centerX = arithmetic.normalizeAroundZero(this.x.start + halfLengthX);
        b.min.x = centerX - halfLengthX;
        b.max.x = centerX + halfLengthX;
        b.min.y = this.y[0];
        b.max.y = this.y[1];
        return b;
    }
    toBoxAround(b, origin) {
        const arithmetic = new Arithmetic(this.divisor);
        const halfLengthX = this.x.length * 0.5;
        const centerX = arithmetic.normalizeAround(this.x.start + halfLengthX, origin.x);
        b.min.x = centerX - halfLengthX;
        b.max.x = centerX + halfLengthX;
        b.min.y = this.y[0];
        b.max.y = this.y[1];
        return b;
    }
    center() {
        return new CylinderPoint(this.divisor).set(this.x.center(), (this.y[0] + this.y[1]) * 0.5);
    }
    size(v) {
        v.x = this.x.length;
        v.y = this.y[1] - this.y[0];
        return v;
    }
    intersectsBox(box) {
        const verticallyIntersects = this.y[1] > box.y[0] && this.y[0] < box.y[1];
        return verticallyIntersects && this.x.intersects(box.x);
    }
    contains(point) {
        const verticallyIn = this.y[0] <= point.y && point.y <= this.y[1];
        return verticallyIn && this.x.contains(point.x);
    }
    clamp(p) {
        return p.clone().set(this.x.clamp(p.x), clamp(p.y, this.y[0], this.y[1]));
    }
    farthestFrom(p) {
        return p.clone().set(this.x.farthestFrom(p.x), farthestFrom(p.y, this.y));
    }
    toString() {
        return `[CylinderBox (${this.x.start}, ${this.y[0]}) -> (${this.x.end()}, ${this.y[1]}) mod ${this.divisor}]`;
    }
    get [Symbol.toStringTag]() {
        return this.toString();
    }
    /** Note: is not associative */
    static boundingBox(divisor, boxes) {
        if (boxes.length === 0)
            return new CylinderBox(divisor);
        const x = Range.boundingRange(divisor, boxes.map(b => b.x));
        const y = boxes
            .map(b => b.y)
            .reduce((result, r) => {
            result[0] = Math.min(result[0], r[0]);
            result[1] = Math.max(result[1], r[1]);
            return result;
        }, CylinderBox.emptyY());
        return new CylinderBox(boxes[0].divisor).set(x, y);
    }
    static splitIntoQuadrants(box, splitAnchor) {
        const divisor = box.divisor;
        const start = box.start();
        const end = box.end();
        assertStatement(() => box.contains(splitAnchor), 'Split anchor must be inside the box');
        return [
            new CylinderBox(divisor).fromStartEnd(start, splitAnchor),
            new CylinderBox(divisor).fromStartEnd(new CylinderPoint(divisor).set(start.x, splitAnchor.y), new CylinderPoint(divisor).set(splitAnchor.x, end.y)),
            new CylinderBox(divisor).fromStartEnd(splitAnchor, end),
            new CylinderBox(divisor).fromStartEnd(new CylinderPoint(divisor).set(splitAnchor.x, start.y), new CylinderPoint(divisor).set(end.x, splitAnchor.y)),
        ];
    }
}
registerType('CylinderPoint', {
    replacer: (point) => (Object.assign({}, point)),
    reviver: value => new CylinderPoint(value.divisor).set(value.x, value.y),
});
export class Arithmetic {
    constructor(divisor) {
        this.divisor = divisor;
    }
    /** in range [0; M] */
    normalize(a) {
        return positiveModulo(a, this.divisor);
    }
    /** in range [origin - M/2; origin + M/2] */
    normalizeAround(a, origin) {
        const offset = this.divisor * 0.5 - origin;
        return this.normalize(a + offset) - offset;
    }
    /** in range [-M/2; M/2] */
    normalizeAroundZero(a) {
        return this.normalizeAround(a, 0);
    }
    distance(a, b) {
        return Math.min(this.normalize(a - b), this.normalize(b - a));
    }
    signedDistance(a, b) {
        const straight = b - a;
        const left = -a - this.divisor + b;
        const right = this.divisor - a + b;
        return [straight, left, right].reduce((result, path) => Math.abs(result) < Math.abs(path) ? result : path);
    }
    /** distance for "moving" only in positive direction */
    rightDistance(a, b) {
        return this.normalize(b - a);
    }
}
