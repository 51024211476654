export var MouseButtons;
(function (MouseButtons) {
    MouseButtons[MouseButtons["LEFT"] = 1] = "LEFT";
    MouseButtons[MouseButtons["MIDDLE"] = 4] = "MIDDLE";
    MouseButtons[MouseButtons["RIGHT"] = 2] = "RIGHT";
})(MouseButtons || (MouseButtons = {}));
export var Axis;
(function (Axis) {
    Axis["X"] = "x";
    Axis["Y"] = "y";
    Axis["Z"] = "z";
})(Axis || (Axis = {}));
