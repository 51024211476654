// this is a hack around webpack CORS worker limitations https://github.com/webpack/webpack/discussions/14648#discussioncomment-1589272
export class CorsWorker {
    constructor(url, options) {
        this.url = url;
        this.options = options;
    }
    createWorker() {
        const absoluteUrl = new URL(this.url, window.location.href).toString();
        const workerSource = `\
    const urlString = ${JSON.stringify(absoluteUrl)}
    const originURL = new URL(urlString)
    const isValidUrl = (urlString) => {
        try { return Boolean(new URL(urlString, originURL)) } catch(e){ return false }
    }
    const originalImportScripts = self.importScripts
    self.importScripts = (url) => {
        /* see note 1 below */
        if(url.startsWith("blob:") && isValidUrl(url.replace("blob:", ""))){
        const urlWithoutBlob = url.replace("blob:", "")
        const { pathname } = new URL(urlWithoutBlob, originURL)
        url = pathname && pathname.substring(1) /* see note 2 below */
        }
        originalImportScripts.call(self, new URL(url, originURL).toString())
    }
    importScripts(urlString);
    `;
        const blob = new Blob([workerSource], { type: 'application/javascript' });
        const objectURL = URL.createObjectURL(blob);
        const worker = new Worker(objectURL, this.options);
        URL.revokeObjectURL(objectURL);
        return worker;
    }
}
