export default class EventManager {
    getCallbacks(key) {
        var _a;
        const listeners = (_a = this.callbacks.get(key)) !== null && _a !== void 0 ? _a : [];
        this.callbacks.set(key, listeners);
        return listeners;
    }
    constructor() {
        this.callbacks = new Map();
    }
    on(eventType, callback) {
        this.getCallbacks(eventType.key).push(callback);
    }
    off(eventType, callback) {
        const listeners = this.getCallbacks(eventType.key);
        const index = listeners.indexOf(callback);
        if (index !== -1)
            listeners.splice(index, 1);
    }
    send(eventType, data) {
        this.getCallbacks(eventType.key).forEach(callback => callback(data));
    }
    trigger(eventType) {
        this.send(eventType, undefined);
    }
    // eslint-disable-next-line class-methods-use-this
    createEvent(key) {
        var _a;
        return _a = class {
                constructor(d) {
                    this.data = d;
                }
            },
            _a.key = key,
            _a;
    }
}
