import { Vector2 } from 'three';
const CLICK_MARGIN_OF_ERROR = 5;
export const isScrollingTrackPad = (event) => Number.isInteger(event.deltaY);
export const getTouchAsVector = (event) => new Vector2(event.changedTouches[0].pageX, event.changedTouches[0].pageY);
export const getTouchDistance = (touch1, touch2) => {
    const from = new Vector2(touch1.pageX, touch1.pageY);
    const to = new Vector2(touch2.pageX, touch2.pageY);
    return from.distanceTo(to);
};
export const getPinchCenter = (touch1, touch2) => {
    const middleX = (touch1.pageX + touch2.pageX) / 2;
    const middleY = (touch1.pageY + touch2.pageY) / 2;
    return new Vector2(middleX, middleY);
};
export const getEndTouchPosition = (event) => new Vector2(event.changedTouches[0].pageX, event.changedTouches[0].pageY);
function getCursorPosition(event) {
    return new Vector2(event.clientX, event.clientY);
}
export function isMouseClick(startEvent, endEvent) {
    const startPosition = getCursorPosition(startEvent);
    const endPosition = getCursorPosition(endEvent);
    const moveDistance = startPosition.distanceTo(endPosition);
    return moveDistance < CLICK_MARGIN_OF_ERROR;
}
export function isLeftMouseButton(event) {
    return event.button === 0;
}
