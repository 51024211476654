import { HTMLUtils } from '../utils/html_utils';
import { InputEventUtils } from '../utils/input_event_utils';
import Router from '../router';
import { NAVIGATION_ARROW_TYPES } from '../interfaces/planogram.interface';
import { Metrics } from '../metrics';
import { MATOMO_EVENT_NAMES } from '../metric-events';
import { isTouchSupported } from '../utils/platform';
export class NavigationArrows {
    constructor(planogram, inputHandler) {
        this.planogram = planogram;
        this.inputHandler = inputHandler;
        this.initButtons();
        this.handleClicks();
    }
    initButtons() {
        this.container = document.getElementById('navigation-arrows-container');
        this.renderView();
        this.applyStyles();
    }
    renderView() {
        const leftArrow = document.createElement('div');
        const leftArrowIconContainer = document.createElement('div');
        const rightArrow = document.createElement('div');
        const rightArrowIconContainer = document.createElement('div');
        leftArrow.classList.add('navigation-arrow-button', 'left-arrow');
        leftArrowIconContainer.classList.add('navigation-arrow-button-icon-wrapper');
        rightArrow.classList.add('navigation-arrow-button', 'right-arrow');
        rightArrowIconContainer.classList.add('navigation-arrow-button-icon-wrapper');
        leftArrow.appendChild(leftArrowIconContainer);
        rightArrow.appendChild(rightArrowIconContainer);
        this.container.appendChild(leftArrow);
        this.container.appendChild(rightArrow);
    }
    applyStyles() {
        const root = document.documentElement;
        const { navigationArrow } = this.planogram;
        const leftButton = this.container.querySelector('.left-arrow .navigation-arrow-button-icon-wrapper');
        const rightButton = this.container.querySelector('.right-arrow .navigation-arrow-button-icon-wrapper');
        root.style.setProperty('--navigation-arrow-bg-color', navigationArrow.background_color);
        if (navigationArrow.background_color) {
            root.style.setProperty('--navigation-arrow-glow-color', `${navigationArrow.background_color}3d`);
        }
        if (navigationArrow.distribute_arrows && isTouchSupported) {
            this.container.classList.add('distribute');
        }
        if (navigationArrow.disable_glow_effect) {
            this.container.classList.add('without-glow');
        }
        this.addIcon(leftButton, NAVIGATION_ARROW_TYPES.LEFT);
        this.addIcon(rightButton, NAVIGATION_ARROW_TYPES.RIGHT);
    }
    addIcon(button, direction) {
        const img = this.planogram.createImage(direction, '');
        button.appendChild(img);
        return button;
    }
    updateUrl() {
        var _a, _b, _c;
        const animation = (_c = (_b = (_a = this.animationPath) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b[this.inputHandler.currentAnimationIndex]) !== null && _c !== void 0 ? _c : null;
        if (animation) {
            Router.navigateToPlanogramWithPath(animation.planogramName, this.animationPath.name, {
                langCode: animation.language,
                replace: true,
                silent: true
            }, this.inputHandler.currentAnimationIndex);
            this.inputHandler.handleInitAnimationPath(this.animationPath.name, true);
        }
    }
    handleClicks() {
        var _a;
        const previousArrow = this.container.querySelector('.left-arrow');
        const nextArrow = this.container.querySelector('.right-arrow');
        const animationIdentifier = (_a = this.planogram.navigationArrow.animation_path) === null || _a === void 0 ? void 0 : _a.name;
        this.animationPath = this.inputHandler.handleInitAnimationPath(animationIdentifier, true);
        InputEventUtils.addSelectEvents(previousArrow, () => {
            if (!this.animationPath || this.inputHandler.animationPath.uuid !== this.animationPath.uuid) {
                this.animationPath = this.inputHandler.handleInitAnimationPath(animationIdentifier, true);
            }
            else {
                this.inputHandler.handleNextAnimation(true);
            }
            this.updateUrl();
            Metrics.storeTheEvent(this.planogram.name, 'click', MATOMO_EVENT_NAMES.NAVIGATION_ARROW_CLICK('previous'));
        });
        InputEventUtils.addSelectEvents(nextArrow, () => {
            if (!this.animationPath || this.inputHandler.animationPath.uuid !== this.animationPath.uuid) {
                this.animationPath = this.inputHandler.handleInitAnimationPath(animationIdentifier, true);
            }
            else {
                this.inputHandler.handleNextAnimation(false);
            }
            this.updateUrl();
            Metrics.storeTheEvent(this.planogram.name, 'click', MATOMO_EVENT_NAMES.NAVIGATION_ARROW_CLICK('next'));
        });
    }
    dispose() {
        this.animationPath = undefined;
        this.container.classList.remove('without-glow', 'distribute');
        HTMLUtils.removeChildElements(this.container);
    }
}
