var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { assertDefined, debugCommand, debugLog } from 'shared/utils/debug';
import { TRANSPARENT_TILE } from './TileMap';
import { iterateLevels, computeImageMapSize } from './helpers';
import { OperationOutcome, } from './interfaces';
import { WorkerMessageType } from './interfaces';
import MultiSet from './MultiSet';
import { receiveSerializedMessages, sendSerializedMessage } from '../utils/serialization.util';
import { cleanSlot, SlotMap } from './operations';
import { CorsWorker as Worker } from '../utils/CorsWorker';
// at least one slot is required to run any operations
const OPERATION_RUNTIME_SLOTS = 1;
// time without tasks that has to pass to consider tile loader out of work
const IDLE_WINDOW = 100;
function runOperation(context, operation, usedSlotsLimit) {
    return __awaiter(this, void 0, void 0, function* () {
        let result = false;
        try {
            yield operation.preload(context);
            if (operation.usedSlots > usedSlotsLimit) {
                operation.cancel(context);
            }
            else {
                operation.execute(context);
                result = true;
            }
        }
        catch (e) {
            if (e.message !== 'Canceled') {
                console.warn('Failed operation', e.toString());
                try {
                    operation.cancel(context);
                }
                catch (e) {
                    console.warn('Failed to cancel operation', e.toString());
                }
            }
        }
        return result;
    });
}
// TODO: clean up chunkId vs chunk index vs chunk coords mess
export default class TileLoader {
    constructor(tileMap, textureCache, physicalTextures, cdnUrl, loadingLimit, unloadedLevelBias, blacklistDuration, targetPixelRatio) {
        this.tileMap = tileMap;
        this.textureCache = textureCache;
        this.physicalTextures = physicalTextures;
        this.cdnUrl = cdnUrl;
        this.images = new Map();
        this.slotMap = new SlotMap(); // level => (chunkId => slotId)
        this.affectedChunks = new MultiSet();
        this.runningOperations = new Set();
        this.reservedSlots = 0;
        debugCommand('lodLoader', () => this);
        const workerLoader = new Worker(new URL('./OperationSelectionWorker.ts', import.meta.url), {
            type: 'module',
            name: 'lod-worker',
        });
        this.worker = workerLoader.createWorker();
        receiveSerializedMessages(this.worker, message => this.receiveMessage(message));
        const initiateMessage = {
            type: WorkerMessageType.Initiate,
            loadingLimit,
            blacklistDuration,
            unloadedLevelBias,
            targetPixelRatio,
            tileMapResolution: this.tileMap.resolution,
        };
        sendSerializedMessage(this.worker, initiateMessage);
    }
    updateOutOfWork() {
        this.timeOutOfWork = new Date().getTime() + IDLE_WINDOW;
    }
    receiveMessage(message) {
        switch (message.type) {
            case WorkerMessageType.OperationTarget: {
                this.updateOutOfWork();
                const { operation, id } = message;
                assertDefined(operation, 'Received undefined operation from the worker');
                const availableSlots = this.physicalTextures.freeSlots - this.reservedSlots;
                if (operation.affectedChunkIds.some(it => this.affectedChunks.has(it)) ||
                    availableSlots < operation.reservedSlots) {
                    operation.cancel(this);
                    this.reportOperationOutcome(id, OperationOutcome.Cancelation);
                    break;
                }
                const maxSlotsToUse = availableSlots - OPERATION_RUNTIME_SLOTS;
                this.runningOperations.add(operation);
                this.reservedSlots += operation.reservedSlots;
                debugLog(`Running operation ${operation} with ${maxSlotsToUse} slots to use, ${this.reservedSlots} reserved slots, ${this.physicalTextures.freeSlots - this.reservedSlots} unclaimed slots remaining`);
                operation.affectedChunkIds.forEach(chunkId => this.affectedChunks.add(chunkId));
                runOperation(this, operation, maxSlotsToUse)
                    .then(success => {
                    this.reportOperationOutcome(id, success ? OperationOutcome.Success : OperationOutcome.Failure);
                })
                    .finally(() => {
                    this.runningOperations.delete(operation);
                    this.reservedSlots -= operation.reservedSlots;
                    operation.affectedChunkIds.forEach(chunkId => this.affectedChunks.remove(chunkId));
                });
                break;
            }
            case WorkerMessageType.CleanSlots: {
                const image = this.images.get(message.imageId);
                assertDefined(image, 'Attemp to clear an already deleted image');
                Object.entries(message.chunkMasks).forEach(it => {
                    const [chunkIdKey, mask] = it;
                    const chunkId = parseInt(chunkIdKey);
                    iterateLevels(mask, 
                    // TODO: does this cancel all loading tiles?
                    level => cleanSlot(this, image, level, chunkId));
                });
                this.images.delete(message.imageId);
            }
        }
    }
    reportOperationOutcome(id, outcome) {
        const message = {
            type: WorkerMessageType.OperationResult,
            id,
            outcome,
        };
        sendSerializedMessage(this.worker, message);
    }
    addImage(image) {
        const message = {
            type: WorkerMessageType.UpdateImage,
            data: image,
        };
        sendSerializedMessage(this.worker, message);
        this.tileMap.storeTileLocation(image.mapPosition, computeImageMapSize(image.lodData), TRANSPARENT_TILE);
        this.images.set(image.id, image);
    }
    updateImage(id, extraData) {
        const image = this.images.get(id);
        if (image === undefined)
            return;
        const newImage = Object.assign(Object.assign({}, image), { extraData: Object.assign(Object.assign({}, extraData), { position: extraData.position.clone() }) });
        const message = {
            type: WorkerMessageType.UpdateImage,
            data: newImage,
        };
        sendSerializedMessage(this.worker, message);
        this.images.set(id, newImage);
    }
    removeImage(image) {
        const message = {
            type: WorkerMessageType.RemoveImage,
            id: image.id,
        };
        sendSerializedMessage(this.worker, message);
    }
    update(focusPoint, pixelRatio) {
        if (this.images.size === 0)
            return true;
        const message = {
            type: WorkerMessageType.Frame,
            focusPointX: focusPoint.x,
            focusPointY: focusPoint.y,
            pixelRatio: pixelRatio,
            slots: this.physicalTextures.freeSlots - this.reservedSlots,
        };
        sendSerializedMessage(this.worker, message);
        return this.timeOutOfWork !== undefined && this.timeOutOfWork <= new Date().getTime(); // TODO: return true when worker confirms no work?
    }
    dispose() {
        this.worker.terminate();
    }
}
