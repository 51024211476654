import pipe from 'callbag-pipe';
import subscribe from 'callbag-subscribe';
import { QuantityPickerView } from './quantity-picker.view';
import { InputEventUtils } from '../../../../utils/input_event_utils';
import { AppUtils } from '../../../../utils/app_utils';
export class QuantityPicker {
    constructor(product, mainContainer, shopifyService, shoppingCartService) {
        this.product = product;
        this.mainContainer = mainContainer;
        this.shopifyService = shopifyService;
        this.shoppingCartService = shoppingCartService;
        this.quantity = 1;
        this.availableQuantity = 0;
        this.changeQuantity = this.changeQuantity.bind(this);
        this.initComponent();
        const quantityButtons = this.mainContainer.querySelectorAll('.ecommerce-product-details-item-quantity-button');
        quantityButtons.forEach(button => {
            InputEventUtils.addSelectEvents(button, this.changeQuantity);
        });
        this.selectedProductSubjectRef = pipe(this.shopifyService.selectedProductSubject, subscribe({
            next: (variant) => {
                if (variant) {
                    this.variant = variant;
                    this.quantity = 1;
                    this.updateAvailableQuantity();
                    this.updateQuantityLabel();
                    this.updateDisableState();
                }
            }
        }));
    }
    get product_quantity() {
        return this.quantity;
    }
    get per_order_limit() {
        var _a;
        return (_a = this.variant) === null || _a === void 0 ? void 0 : _a.per_order_limit;
    }
    get product_variant_id() {
        var _a;
        return (_a = this.variant) === null || _a === void 0 ? void 0 : _a.variant_id;
    }
    get product_variant_inventory_quantity() {
        var _a;
        return this.per_order_limit || ((_a = this.variant) === null || _a === void 0 ? void 0 : _a.inventory_quantity);
    }
    get product_variant_available_quantity() {
        return this.availableQuantity;
    }
    initComponent() {
        var _a;
        const quantityPickerContainer = this.mainContainer.querySelector('.app-ecommerce-quantity-picker-container');
        const quantityPicker = document.createElement('div');
        quantityPickerContainer.append(quantityPicker);
        quantityPicker.outerHTML = QuantityPickerView((_a = this.product) === null || _a === void 0 ? void 0 : _a.per_order_limit);
        this.increaseButton = quantityPickerContainer.querySelector('.app-shopping-cart-item-increase');
        this.decreaseButton = quantityPickerContainer.querySelector('.app-shopping-cart-item-decrease');
        this.quantityLabel = quantityPickerContainer.querySelector('.ecommerce-product-details-item-quantity-value');
        this.limitationLabel = quantityPickerContainer.querySelector('.ecommerce-product-details-item-quantity-limit');
    }
    changeQuantity(e) {
        const operation = e.target.classList.contains('app-shopping-cart-item-increase') ? 'increase' : 'decrease';
        switch (operation) {
            case 'increase': {
                if (this.quantity + 1 <= this.variant.inventory_quantity ||
                    !this.variant.inventory_quantity && this.quantity + 1 <= this.variant.quantity) {
                    if (this.per_order_limit && this.quantity + 1 > this.per_order_limit) {
                        return;
                    }
                    this.quantity++;
                    this.updateQuantityLabel();
                }
                break;
            }
            case 'decrease': {
                if (this.quantity - 1 >= 1) {
                    this.quantity--;
                    this.updateQuantityLabel();
                }
                break;
            }
        }
        this.updateDisableState();
    }
    updateQuantityLabel() {
        var _a, _b, _c;
        this.quantityLabel.innerText = this.quantity.toString();
        if ((_a = this.variant) === null || _a === void 0 ? void 0 : _a.isAutoSelected) {
            (_b = this.limitationLabel) === null || _b === void 0 ? void 0 : _b.classList.add('is-hidden');
        }
        else {
            (_c = this.limitationLabel) === null || _c === void 0 ? void 0 : _c.classList.remove('is-hidden');
        }
    }
    updateDisableState() {
        var _a;
        (this.per_order_limit && this.quantity >= this.per_order_limit) ||
            this.quantity >= ((_a = this.variant) === null || _a === void 0 ? void 0 : _a.inventory_quantity)
            ? this.increaseButton.classList.add('disabled')
            : this.increaseButton.classList.remove('disabled');
        this.quantity <= 1
            ? this.decreaseButton.classList.add('disabled')
            : this.decreaseButton.classList.remove('disabled');
    }
    updateAvailableQuantity() {
        var _a, _b;
        const variantsWithSameId = (_a = this.shoppingCartService.shoppingCart) === null || _a === void 0 ? void 0 : _a.cart.filter(item => item.merchandiseId === this.product_variant_id);
        const overallQuantity = AppUtils.overallQuantity(this.shoppingCartService.shoppingCart, this.variant, this.per_order_limit);
        if (variantsWithSameId === null || variantsWithSameId === void 0 ? void 0 : variantsWithSameId.length) {
            if (this.per_order_limit) {
                this.availableQuantity = this.per_order_limit - overallQuantity;
                return;
            }
            this.availableQuantity = this.variant.inventory_quantity - overallQuantity;
        }
        else {
            if (this.per_order_limit) {
                this.availableQuantity = this.per_order_limit - overallQuantity;
                return;
            }
            this.availableQuantity = (_b = this.variant) === null || _b === void 0 ? void 0 : _b.inventory_quantity;
        }
    }
    dispose() {
        this.selectedProductSubjectRef();
    }
}
