export var LodSource;
(function (LodSource) {
    LodSource["DEFAULT"] = "default";
    LodSource["FIGMA"] = "figma";
    LodSource["BACKGRDOUND"] = "background";
})(LodSource || (LodSource = {}));
export var OperationDirection;
(function (OperationDirection) {
    OperationDirection[OperationDirection["Upgrade"] = 1] = "Upgrade";
    OperationDirection[OperationDirection["Downgrade"] = -1] = "Downgrade";
})(OperationDirection || (OperationDirection = {}));
export var WorkerMessageType;
(function (WorkerMessageType) {
    WorkerMessageType[WorkerMessageType["Initiate"] = 0] = "Initiate";
    WorkerMessageType[WorkerMessageType["UpdateImage"] = 1] = "UpdateImage";
    WorkerMessageType[WorkerMessageType["RemoveImage"] = 2] = "RemoveImage";
    WorkerMessageType[WorkerMessageType["Frame"] = 3] = "Frame";
    WorkerMessageType[WorkerMessageType["OperationTarget"] = 4] = "OperationTarget";
    WorkerMessageType[WorkerMessageType["OperationBatch"] = 5] = "OperationBatch";
    WorkerMessageType[WorkerMessageType["OperationResult"] = 6] = "OperationResult";
    WorkerMessageType[WorkerMessageType["CleanSlots"] = 7] = "CleanSlots";
})(WorkerMessageType || (WorkerMessageType = {}));
export var OperationOutcome;
(function (OperationOutcome) {
    OperationOutcome[OperationOutcome["Success"] = 0] = "Success";
    OperationOutcome[OperationOutcome["Failure"] = 1] = "Failure";
    OperationOutcome[OperationOutcome["Cancelation"] = 2] = "Cancelation";
})(OperationOutcome || (OperationOutcome = {}));
