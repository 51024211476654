/* eslint-disable import/no-cycle */
import { PerspectiveCamera, Vector2, Vector3, MathUtils, Ray, Raycaster, Box2, EventDispatcher, } from 'three';
import { getCameraZOffset, getFractionOfFlatteningAngle, getFullFlatteningAngle, getHorizonLineOffset, panAngleToPlanogramX, } from 'shared/utils/CameraUtils';
import cameraConfig from 'shared/config/CameraConfig';
import planogramConfig from 'shared/config/PlanogramConfig';
import { ViewableLimits } from 'shared/renderingEngine/ViewableLimits';
import { rotateToYZPlane } from 'shared/utils/GeometryUtils';
import { SphereShape } from 'shared/utils/SphereShape';
import { assertDefined } from '../utils/debug';
import { degToRad } from 'three/src/math/MathUtils';
import PlanogramPoint from '../utils/PlanogramPoint';
// TODO: de-depulicate with viewer
export function normalizeMouse(x, y) {
    return new Vector2((x / window.innerWidth) * 2 - 1, -((y / window.innerHeight) * 2) + 1);
}
class SphereCamera extends EventDispatcher {
    constructor(size, horizonLine, initialPanAngle) {
        super();
        this.panAngle = 0;
        this.tiltAngle = 0;
        this.flatteningPivotPoint = new Vector3();
        this.flatteningTiltAngle = 0;
        this.viewableLimits = new ViewableLimits(planogramConfig.VIEWABLE_LIMIT_TOP, planogramConfig.VIEWABLE_LIMIT_BOTTOM);
        this.canvasSize = new Vector2(size.width, size.height);
        this._horizonLine = horizonLine;
        this.initialPanAngle = -MathUtils.degToRad(initialPanAngle);
        this.init();
    }
    get canvasSize() {
        return this._canvasSize;
    }
    get fov() {
        return this.camera.fov;
    }
    set canvasSize(newSize) {
        this._canvasSize = newSize;
        if (this.camera === undefined)
            return;
        const newAspectRatio = newSize.x / newSize.y;
        this.camera.aspect = newAspectRatio;
        this.camera.updateProjectionMatrix();
        this.dispatchEvent({ type: 'Updated' });
    }
    get horizonLine() {
        return this._horizonLine;
    }
    set horizonLine(newHorizonLine) {
        this._horizonLine = newHorizonLine;
        this.initCameraPosition();
        this.initialPanAngle = this.panAngle;
        this.initCameraAngles();
        this.refreshCamera();
    }
    get direction() {
        return this.camera.getWorldDirection(new Vector3());
    }
    // TODO: figure out the purpose of three different zoom measurements
    get zoomFraction() {
        var _a;
        return (1.0 -
            (this.camera.fov - cameraConfig.FOV_MIN) /
                (((_a = this.maxFOV) !== null && _a !== void 0 ? _a : cameraConfig.FOV_DEFAULT) - cameraConfig.FOV_MIN));
    }
    get zoom() {
        return this.maxFOV / this.camera.fov;
    }
    zoomRatio() {
        return Math.tan(degToRad(this.maxFOV)) / Math.tan(degToRad(this.camera.fov));
    }
    // TODO: replace uses with planogramPoint(), figure out the offset and the purpose of the correction factor
    get planogramCoordinatesCurator() {
        var _a, _b;
        const cameraFocusIntersection = this.getIntersection();
        let yOffset = 0;
        if (cameraFocusIntersection) {
            const offsetCorrectionFactor = 1.18; // set by trial and error
            yOffset =
                ((_b = (_a = this.sphereShape.reverse(cameraFocusIntersection)) === null || _a === void 0 ? void 0 : _a.y) !== null && _b !== void 0 ? _b : 0) * offsetCorrectionFactor;
        }
        const planogramY = planogramConfig.HEIGHT * yOffset;
        return {
            x: panAngleToPlanogramX(this.panAngle, planogramConfig.WIDTH),
            y: planogramY,
        };
    }
    planogramPoint() {
        const ray = new Ray(this.camera.position, this.direction);
        const t = this.sphereShape.castRayFarthest(ray);
        if (!t)
            return new PlanogramPoint();
        const spherePoint = this.sphereShape.reverse(ray.at(t, new Vector3()));
        if (!spherePoint)
            return new PlanogramPoint();
        return new PlanogramPoint(this.sphereShape.planogramCoordinateViewer(spherePoint, new Vector2(planogramConfig.WIDTH, planogramConfig.HEIGHT)));
    }
    init() {
        const camera = new PerspectiveCamera(20, // TODO: set to real maxFOV
        this.canvasSize.x / this.canvasSize.height, cameraConfig.NEAR_PLANE, cameraConfig.FAR_PLANE);
        this.camera = camera;
        this.sphereShape = new SphereShape(planogramConfig.ALPHA, 3, planogramConfig.WIDTH);
        this.initCameraPosition();
        this.initCameraAngles();
        this.refreshCamera();
    }
    initCameraPosition() {
        this.camera.position.set(0, 0, 0);
        this.camera.rotation.set(0, 0, 0);
        this.camera.translateOnAxis(new Vector3(0, 1, 0), getHorizonLineOffset(this.horizonLine));
        this.camera.translateOnAxis(new Vector3(0, 0, 1), getCameraZOffset());
        this.initialCameraPosition = this.camera.position.clone();
    }
    initialZPosition() {
        return this.initialCameraPosition.z;
    }
    initCameraAngles() {
        this.initialCameraTilt = Math.atan2(this.camera.position.y, this.camera.position.z + planogramConfig.EQUATOR_RADIUS);
        this.updateMaxFOV();
        this.updateTiltAngle();
        this.panAngle = 0;
        this.updatePanAngle(this.initialPanAngle);
    }
    tiltAndPan(adjustment) {
        var _a, _b;
        const angleToPan = (_a = adjustment.pan) !== null && _a !== void 0 ? _a : 0;
        const angleToTilt = (_b = adjustment.tilt) !== null && _b !== void 0 ? _b : 0;
        this.updateFlatteningAngle();
        if (angleToTilt !== 0) {
            this.updateTiltAngle(angleToTilt);
        }
        if (angleToPan !== 0) {
            this.updatePanAngle(angleToPan);
        }
        this.refreshCamera();
    }
    zoomBy(zoomFactor) {
        const newFov = this.camera.fov * zoomFactor;
        this.zoomTo(newFov);
    }
    zoomTo(newFov) {
        this.camera.fov = this.clampFOV(newFov);
        this.updateFlatteningAngle();
        this.refreshCamera();
    }
    getIntersection(direction = this.direction, position = this.camera.position) {
        return this.sphereShape.castRayFarthestPoint(new Ray(position, direction), new Vector3());
    }
    getMouseIntersection(x, y) {
        const mousePoint = normalizeMouse(x, y);
        const caster = new Raycaster();
        caster.setFromCamera(mousePoint, this.camera);
        return this.sphereShape.castRayFarthestPoint(caster.ray, new Vector3());
    }
    clampFOV(fov) {
        return MathUtils.clamp(fov, cameraConfig.FOV_MIN, this.maxFOV);
    }
    updatePanAngle(angleToPan = 0) {
        // Keeps the angle between -180 and +180 deg
        this.panAngle = ((this.panAngle + angleToPan + Math.PI) % (Math.PI * 2)) - Math.PI;
    }
    updateTiltAngle(angleToTilt = 0) {
        this.tiltAngle = this.viewableLimits.newAngle(this.camera, this.tiltAngle, this.flatteningTiltAngle, angleToTilt);
    }
    updateFlatteningAngle() {
        const cameraRay = new Ray(this.camera.position, this.direction);
        const intersect = this.sphereShape.castRayFarthest(new Ray(this.camera.position, this.direction));
        if (intersect === undefined)
            return;
        const point = cameraRay.at(intersect, new Vector3());
        const spherePoint = this.sphereShape.reverse(point);
        assertDefined(spherePoint, 'Camera is not looking at the sphere');
        const normal = this.sphereShape.normalAt(spherePoint);
        const cameraPosition = this.initialCameraPosition.clone();
        this.flatteningPivotPoint = rotateToYZPlane(point);
        this.flatteningPivotPoint.z *= -1;
        // Make pivot point the origin for camera vector
        cameraPosition.sub(this.flatteningPivotPoint);
        const fullFlatteningAngle = getFullFlatteningAngle(cameraPosition, rotateToYZPlane(normal));
        const fullAjustmentAngle = fullFlatteningAngle - this.initialCameraTilt;
        this.flatteningTiltAngle = getFractionOfFlatteningAngle(fullAjustmentAngle, this.zoomFraction, cameraConfig.ZOOM_FLATTENING_START, cameraConfig.ZOOM_FLATTENING_END);
    }
    updateMaxFOV() {
        this.maxFOV = this.viewableLimits.maxFOV(this.camera.position);
        this.camera.fov = this.maxFOV;
    }
    applyFlattening() {
        this.camera.translateX(this.flatteningPivotPoint.x);
        this.camera.translateY(this.flatteningPivotPoint.y);
        this.camera.translateZ(this.flatteningPivotPoint.z);
        this.camera.rotateX(this.flatteningTiltAngle);
        this.camera.translateX(-this.flatteningPivotPoint.x);
        this.camera.translateY(-this.flatteningPivotPoint.y);
        this.camera.translateZ(-this.flatteningPivotPoint.z);
    }
    refreshCamera() {
        this.camera.position.set(0, 0, 0);
        this.camera.rotation.set(0, 0, 0);
        this.camera.updateProjectionMatrix();
        this.camera.rotateY(this.panAngle);
        this.applyFlattening();
        this.camera.translateX(this.initialCameraPosition.x);
        this.camera.translateY(this.initialCameraPosition.y);
        this.camera.translateZ(this.initialCameraPosition.z);
        this.camera.rotateX(this.tiltAngle);
        this.camera.updateMatrix();
        this.camera.updateMatrixWorld();
        this.dispatchEvent({ type: 'Updated' });
    }
    projectCameraRay(direction) {
        const origin = this.camera.position;
        const cameraDirection = direction.unproject(this.camera).sub(origin).normalize();
        const ray = new Ray(origin, cameraDirection);
        const t = this.sphereShape.castRayFarthest(ray);
        if (t === undefined)
            return undefined;
        return ray.at(t, new Vector3());
    }
    sceneToPlanogramCoordinate(v) {
        const sample = this.sphereShape.reverse(v);
        return this.sphereShape.planogramCoordinateViewer(sample, new Vector2(planogramConfig.WIDTH, planogramConfig.HEIGHT));
    }
    computeViewport() {
        const left = this.projectCameraRay(new Vector3(-1, 0, 0));
        const right = this.projectCameraRay(new Vector3(1, 0, 0));
        const top = this.projectCameraRay(new Vector3(0, 1, 0));
        const bottom = this.projectCameraRay(new Vector3(0, -1, 0));
        // TODO some raycasts can fail on the first render frame, needs investigation
        if (left && right && top && bottom) {
            const min = new Vector2(this.sceneToPlanogramCoordinate(left).x, this.sceneToPlanogramCoordinate(bottom).y);
            const max = new Vector2(this.sceneToPlanogramCoordinate(right).x, this.sceneToPlanogramCoordinate(top).y);
            return new Box2(min, max);
        }
        return new Box2();
    }
}
export default SphereCamera;
