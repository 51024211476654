var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SphereItem } from '../sphere_item';
import { VideoUtils } from '../utils/video_utils';
import { VideoControlsComponent } from './video_controls';
import { SPHERE_EVENT_NAMES as EVENTS } from '../event-names';
import { sphereEventHandler } from '../custom_event_utils';
import videoPreloadVertexShader from '../../shaders/standard_vertex_shader.glsl';
import videoPreloadFragmentShader from '../../shaders/video_preload_fragment_shader.glsl';
import textureScaleVertexShader from '../../shaders/texture-scale-vertex.shader.glsl';
import textureScaleFragmentShader from '../../shaders/texture-scale-fragment.shader.glsl';
import standardVertexShader from '../../shaders/standard_vertex_shader.glsl';
import videoTransparencyFragmentShader from 'shared/renderingEngine/shaders/VideoTransparencyFragmentShader.glsl';
import videoFragmentShader from 'shared/renderingEngine/shaders/VideoFragmentShader.glsl';
import { BrowserUtils } from '../utils/browser_utils';
import { Color, DataTexture, Group, LinearFilter, Mesh, RGBAFormat, ShaderMaterial, TextureLoader, Vector2, Vector4, VideoTexture } from 'three';
import { disposeMaterial } from '../utils/disposeThree';
import { Metrics } from '../metrics';
import { AppState } from '../shared/app.state';
import { MATOMO_EVENT_NAMES } from '../metric-events';
import { debugFloatPrameter } from 'shared/utils/debug';
const TOLERANCE = debugFloatPrameter('VIDEO_MASKING_TOLERANCE', 0.05);
const placeholderTexture = new DataTexture(new Uint8Array([255, 255, 255, 0]), 1, 1);
placeholderTexture.needsUpdate = true;
export class VideoComponent extends SphereItem {
    isStuttering() {
        var _a, _b;
        return ((_b = (_a = this.video) === null || _a === void 0 ? void 0 : _a.currentTime) !== null && _b !== void 0 ? _b : 0) <= this.stutteredTime;
    }
    constructor(itemData, planogram) {
        var _a;
        super(itemData, planogram);
        this.isVisible = false;
        this.fullSize = false;
        this.wasPausedManually = false;
        this.disposed = false;
        this.hasLoaded = false;
        this.ended = false;
        this.hasAudio = true;
        this.cachedTime = 0;
        this.isPlaying = false;
        this.onClickItem = event => {
            var _a, _b, _c, _d, _e;
            const nothingClicked = !event.item;
            const notClickedOnThisVideo = ((_a = event.item) === null || _a === void 0 ? void 0 : _a.id) !== this.id && ((_c = (_b = event.item) === null || _b === void 0 ? void 0 : _b.video) === null || _c === void 0 ? void 0 : _c.id) !== this.id;
            if ((nothingClicked || notClickedOnThisVideo) && !this.autoplay) {
                this.pause(true);
            }
            else if (((_e = (_d = event.item) === null || _d === void 0 ? void 0 : _d.video) === null || _e === void 0 ? void 0 : _e.id) === this.id) {
                Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.WEBGL_CLICK_VIDEO(this.itemData.name, this.source, this.video === undefined ? 0 : Math.round(this.video.duration)));
            }
        };
        this.isAllowedToPlay = true;
        const mediaData = itemData.data;
        this.autoplay = mediaData.autoplay;
        this.hideControls = mediaData.hideControls;
        this.fullSize = mediaData.fullSize;
        this.loop = mediaData.loop;
        this.poster = mediaData.poster;
        this.share = (_a = mediaData.share) !== null && _a !== void 0 ? _a : false;
        this.maskColor = mediaData.maskColor;
        const videoUrl = mediaData.videoUrl;
        this.source = VideoUtils.sanitizeUrl(videoUrl);
        this.createVideoElement();
        this.onClickItem = this.onClickItem.bind(this);
    }
    createVideoElement() {
        var _a;
        this.video = document.createElement('video');
        this.video.setAttribute('id', 'video__' + this.itemData.id);
        document.getElementById('video-container').appendChild(this.video);
        this.video.muted = this.autoplay;
        this.video.crossOrigin = 'anonymous';
        this.video.setAttribute('playsinline', '');
        this.video.loop = (_a = this.loop) !== null && _a !== void 0 ? _a : false;
        // without this Android browsers might fail when video is autoplayed immediately after page load with:
        // WebGL warning: texImage: Fast Tex(Sub)Image upload failed without recourse, clearing to [0.2, 0.0, 0.2, 1.0]. Please file a bug!
        this.video.style.display = 'none';
        this.video.onended = () => {
            this.ended = true;
            this.pause();
        };
        this.video.preload = 'metadata';
        this.stutteredTime = -1;
        this.video.onwaiting = () => {
            this.stutteredTime = this.video.currentTime;
        };
    }
    canAutoplay() {
        return this.autoplay && this.isAllowedToPlay && !this.wasPausedManually && !this.ended;
    }
    preloadVideo() {
        if (this.preloading !== undefined)
            return this.preloading[0];
        if (!this.hideControls)
            this.controls.group.visible = false;
        this.preloaderMesh.visible = !this.autoplay || !this.poster;
        let canceled = false;
        this.preloading = [
            new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                // resolve any redirects to prevent CORS in Safari (e. g. for Vimeo hosted videos)
                const url = yield this.resolveRedirects(this.source);
                if (this.disposed || canceled)
                    return;
                this.video.src = url;
                this.video.onerror = reject; // TODO: throw an actual Error
                const loaded = () => __awaiter(this, void 0, void 0, function* () {
                    if (this.hasLoaded)
                        resolve();
                    if (canceled || url !== this.video.src)
                        reject(new Error('Canceled'));
                    // force the first few seconds of the video to load
                    // preload = auto only loads metadata on slow connections
                    // we can't access the thumbnail from the metadata
                    this.video.currentTime = this.cachedTime;
                    const videoElm = this.video;
                    this.hasAudio = Boolean(videoElm.mozHasAudio ||
                        videoElm.webkitAudioDecodedByteCount ||
                        (videoElm.audioTracks && videoElm.audioTracks.length > 0));
                    this.hasLoaded = true;
                    this.showVideo(false);
                    if (this.canAutoplay())
                        this.play();
                    if (!this.hideControls) {
                        this.controls.group.visible = true;
                        sphereEventHandler.listen(EVENTS.CONTROL.CLICK_ITEM, this.onClickItem);
                    }
                    this.preloaderMesh.visible = false;
                    resolve();
                });
                this.video.onloadedmetadata = loaded;
                this.video.onloadeddata = loaded;
                // have to manually force load on Safari to display first frame
                if (this.video.readyState === 0) {
                    this.video.load();
                }
            })),
            () => {
                canceled = true;
                this.unloadVideo();
            }
        ];
        return this.preloading[0];
    }
    unloadVideo() {
        this.preloading = undefined;
        this.pause();
        this.cachedTime = this.video.currentTime;
        this.hasLoaded = false;
        this.video.onloadeddata = undefined;
        this.video.onloadedmetadata = undefined;
        this.video.onerror = undefined;
        this.video.src = '';
        this.video.load();
        this.preloaderMesh.visible = false;
        this.isAllowedToPlay = true;
        if (!this.hideControls)
            this.controls.group.visible = true;
    }
    play() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isPlaying = true;
            return this.preloadVideo()
                .then(() => this.video.play())
                .then(() => {
                var _a;
                this.ended = false;
                this.showVideo(true);
                (_a = this.controls) === null || _a === void 0 ? void 0 : _a.update();
                this.wasPausedManually = false;
                if (!this.video.muted && this.hasAudio)
                    sphereEventHandler.emit(EVENTS.VIDEO.PLAY_WITH_AUDIO);
                return true;
            })
                .catch(e => {
                var _a;
                if (this.disposed || e.name === 'AbortError') {
                    return false;
                }
                this.isPlaying = false;
                this.pause(false);
                console.warn(e);
                (_a = this.controls) === null || _a === void 0 ? void 0 : _a.update();
                return false;
            });
        });
    }
    resolveRedirects(url) {
        return __awaiter(this, void 0, void 0, function* () {
            return fetch(url, { method: 'HEAD' }).then(response => response.url);
        });
    }
    pause(manual = false) {
        var _a;
        if (!this.hasLoaded || !this.isPlaying)
            return;
        this.showVideo(false);
        if (manual) {
            this.wasPausedManually = true;
        }
        this.video.pause();
        this.isPlaying = false;
        (_a = this.controls) === null || _a === void 0 ? void 0 : _a.update();
        if (!this.video.muted && this.hasAudio)
            sphereEventHandler.emit(EVENTS.VIDEO.STOP_WITH_AUDIO);
    }
    isAutoPlay() {
        return this.autoplay && this.isVisible;
    }
    onClick(position) {
        var _a;
        super.onClick(position);
        (_a = this.controls) === null || _a === void 0 ? void 0 : _a.onClick();
        Metrics.storeTheEvent(AppState.planogramName, 'click', MATOMO_EVENT_NAMES.WEBGL_CLICK_VIDEO(this.itemData.name, this.source, Math.round(this.video.duration)));
    }
    update(elapsedTime) {
        if (this.preloaderMaterial && !this.hasLoaded) {
            this.preloaderMaterial.uniforms.time.value += elapsedTime;
        }
    }
    showVideo(flag) {
        this.videoMesh.visible = flag || this.poster !== undefined;
        this.videoMesh.material = flag || this.poster === undefined ? this.videoMaterial : this.posterMaterial;
    }
    createControls() {
        if (!this.hideControls) {
            this.controls = new VideoControlsComponent(this);
            this.controls.group.renderOrder = this.renderOrder + 0.2;
            this.object3D.add(this.controls.group);
        }
    }
    createPreloaderMaterial() {
        return __awaiter(this, void 0, void 0, function* () {
            const opacity = this.autoplay ? 0.2 : 0.5;
            const uniforms = {
                time: { value: 0 },
                aspectRatio: { value: this.width / this.height },
                color: { value: new Color(0xffffff) },
                scale: { value: 0.33 },
                opacity: { value: opacity }
            };
            return new ShaderMaterial({
                vertexShader: videoPreloadVertexShader,
                fragmentShader: videoPreloadFragmentShader,
                uniforms,
                depthTest: false,
                transparent: true,
                defines: {
                    PI: Math.PI
                }
            });
        });
    }
    createVideoMaterial() {
        return __awaiter(this, void 0, void 0, function* () {
            const videoTexture = new VideoTexture(this.video);
            videoTexture.generateMipmaps = false;
            videoTexture.minFilter = LinearFilter;
            videoTexture.magFilter = LinearFilter;
            videoTexture.format = RGBAFormat; // RGBFormat causes performance issues in Firefox
            return new ShaderMaterial({
                vertexShader: standardVertexShader,
                fragmentShader: this.maskColor !== undefined ? videoTransparencyFragmentShader : videoFragmentShader,
                uniforms: {
                    videoTexture: { value: videoTexture },
                    maskColor: { value: new Color(this.maskColor) }
                },
                defines: {
                    tolerance: TOLERANCE
                },
                depthTest: false,
                transparent: true
            });
        });
    }
    createPosterMaterial() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.poster)
                return;
            const textureLoader = new TextureLoader();
            const posterUrl = this.poster.url + BrowserUtils.pickImageVariant(this.poster.thumbnails, true);
            try {
                const posterTexture = yield textureLoader.loadAsync(posterUrl);
                if (this.disposed) {
                    posterTexture.dispose();
                    return;
                }
                const videoAspect = this.width / this.height;
                const posterAspect = this.poster.naturalWidth / this.poster.naturalHeight;
                const relativePosterAspect = posterAspect / videoAspect;
                const uvScale = relativePosterAspect > 1 ? new Vector2(1, 1 / relativePosterAspect) : new Vector2(relativePosterAspect, 1);
                const uvOffset = relativePosterAspect > 1 ? new Vector2(0, 0.5) : new Vector2(0.5, 0);
                return new ShaderMaterial({
                    vertexShader: textureScaleVertexShader,
                    fragmentShader: textureScaleFragmentShader,
                    uniforms: {
                        imageTexture: { value: posterTexture },
                        backgroundColor: { value: new Vector4(0, 0, 0, 0) },
                        uvScale: { value: uvScale },
                        uvOffset: { value: uvOffset }
                    },
                    depthTest: false,
                    transparent: true
                });
            }
            catch (e) {
                console.error(e);
            }
        });
    }
    onHoverEnter() {
        var _a;
        (_a = this.controls) === null || _a === void 0 ? void 0 : _a.onHoverEnter();
    }
    onHoverLeave() {
        var _a;
        (_a = this.controls) === null || _a === void 0 ? void 0 : _a.onHoverLeave();
    }
    allowToPlay(flag) {
        this.isAllowedToPlay = flag;
        if (flag && !this.isPlaying && this.canAutoplay())
            this.play();
        if (!flag && this.isPlaying)
            this.pause();
    }
    allowToLoad(flag) {
        if (flag)
            this.preloadVideo();
        else if (this.preloading !== undefined)
            this.preloading[1]();
        else
            this.unloadVideo();
    }
    createMesh() {
        return __awaiter(this, void 0, void 0, function* () {
            this.object3D = new Group();
            this.object3D.renderOrder = this.renderOrder;
            this.object3D.userData = {
                component: this,
                itemData: this.itemData
            };
            this.object3D.layers.enable(2);
            return Promise.all([this.createPreloaderMaterial(), this.createVideoMaterial(), this.createPosterMaterial()]).then(([preloaderMaterial, videoMaterial, posterMaterial]) => {
                this.preloaderMaterial = preloaderMaterial;
                this.videoMaterial = videoMaterial;
                this.posterMaterial = posterMaterial;
                this.geometry = this.generateGeometry();
                if (!this.hasLoaded) {
                    const preloaderMesh = new Mesh(this.geometry, preloaderMaterial);
                    preloaderMesh.layers.enable(2);
                    preloaderMesh.renderOrder = this.renderOrder + 0.1;
                    preloaderMesh.visible = false;
                    this.preloaderMesh = preloaderMesh;
                    preloaderMesh.userData = {
                        component: this
                    };
                    this.object3D.add(this.preloaderMesh);
                }
                const videoMesh = new Mesh(this.geometry, this.poster ? this.posterMaterial : this.videoMaterial);
                videoMesh.layers.enable(2);
                videoMesh.renderOrder = this.renderOrder;
                this.object3D.add(videoMesh);
                videoMesh.userData = {
                    component: this
                };
                this.videoMesh = videoMesh;
                this.createControls();
            });
        });
    }
    dispose() {
        var _a;
        this.disposed = true;
        sphereEventHandler.off(EVENTS.CONTROL.CLICK_ITEM, this.onClickItem);
        if (this.video) {
            this.unloadVideo();
            this.video.onended = undefined;
            this.video.onwaiting = undefined;
            this.video.remove();
            this.video = undefined;
        }
        disposeMaterial(this.videoMaterial);
        disposeMaterial(this.preloaderMaterial);
        this.onClickItem = undefined;
        (_a = this.controls) === null || _a === void 0 ? void 0 : _a.dispose();
        this.controls = undefined;
        super.dispose();
    }
}
