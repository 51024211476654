import { Vector2 } from 'three';
import { TILE_CONTENT_SIZE, TILE_SIZE } from 'shared/lod/parameters';
export function skipAtlasLevels(lods) {
    const atlasLess = lods.filter(it => { var _a; return ((_a = it.textures[0]) === null || _a === void 0 ? void 0 : _a.uv) === undefined; });
    // use best atlased level if no non-atlas levels are available
    if (atlasLess.length === 0)
        atlasLess.push(lods[0]);
    return atlasLess;
}
export function computeLevelMapSize(levelData) {
    return Math.round(Math.sqrt(levelData.textures.length));
}
export function computeImageMapSize(lodData) {
    if (lodData.curator_lods.length === 0)
        return 1;
    return computeLevelMapSize(lodData.curator_lods[0]);
}
export function lowestNonAtlasLevel(lodData) {
    // TODO: consider a more efficient way to find last non-atlas level?
    for (let i = lodData.curator_lods.length - 1; i >= 0; i--) {
        const it = lodData.curator_lods[i];
        if (!isAtlas(it))
            return it;
    }
    return lodData.curator_lods[0]; // treat the best atlas level as lowest level
}
export function levelRatio(lodData, level) {
    let lodLevel = lodData.curator_lods[level];
    if (isAtlas(lodLevel))
        lodLevel = lowestNonAtlasLevel(lodData);
    return 1 << lodLevel.lod;
}
export function computeTileMapLocation(lodData, level, index, tileMapOffset) {
    const lodLevel = lodData.curator_lods[level];
    const levelSize = computeLevelMapSize(lodLevel);
    const tileSize = levelRatio(lodData, lodLevel.lod);
    return new Vector2(Math.floor(index / levelSize), levelSize - 1 - (index % levelSize))
        .multiplyScalar(tileSize)
        .add(tileMapOffset);
}
export function mapOffsetToIndex(image, mapOffset, level) {
    const lodLevel = image.lodData.curator_lods[level];
    const levelSize = computeLevelMapSize(lodLevel);
    const tileSize = levelRatio(image.lodData, level);
    const tileMapOffset = mapOffset.clone().sub(image.mapPosition).divideScalar(tileSize);
    return levelSize * Math.floor(tileMapOffset.x) + levelSize - 1 - Math.floor(tileMapOffset.y);
}
export function imageChunkPlanogramPosition(image, mapOffset) {
    const chunkSize = imagePlanogramChunkSize(image);
    const imageMapSize = computeImageMapSize(image.lodData);
    return image.extraData.position.clone().add(mapOffset
        .clone()
        .sub(image.mapPosition)
        .subScalar((imageMapSize - 1) * 0.5) // offset to center of image
        .multiplyScalar(chunkSize));
}
export function iterateChunkPoints(image, callback) {
    const imageMapSize = computeImageMapSize(image.lodData);
    const lodLevel = image.lodData.curator_lods[0];
    for (let x = 0; x < imageMapSize; x++) {
        for (let y = 0; y < imageMapSize; y++) {
            const index = x + y * imageMapSize;
            const mapOffset = computeTileMapLocation(image.lodData, lodLevel.lod, index, image.mapPosition);
            const planogramPosition = imageChunkPlanogramPosition(image, mapOffset);
            callback(mapOffset, planogramPosition);
        }
    }
}
export function imagePlanogramChunkSize(image) {
    var _a, _b, _c;
    const baseLod = image.lodData.curator_lods.find(it => it.lod === 0);
    const baseUV = (_c = (_b = (_a = baseLod.textures[0]) === null || _a === void 0 ? void 0 : _a.uv) === null || _b === void 0 ? void 0 : _b.width) !== null && _c !== void 0 ? _c : 1.0;
    // TOOD: can this just be TILE_CONTENT_SIZE?
    const pixelsPerTile = (Math.max(...image.lodData.full_size) * baseUV) / computeLevelMapSize(baseLod);
    // TODO: measure tiles in 2d for images with changed aspect ratio?
    const imageScaling = Math.max(image.extraData.size.x / image.lodData.fit_size[0], image.extraData.size.y / image.lodData.fit_size[1]);
    return imageScaling * pixelsPerTile;
}
export function isAtlas(lodLevel) {
    var _a;
    return ((_a = lodLevel.textures[0]) === null || _a === void 0 ? void 0 : _a.uv) !== undefined;
}
export function worstLevel(lodData) {
    return lodData.curator_lods.reduce((worst, it) => Math.max(worst, it.lod), 0);
}
export function unloadedLevelEquivalent(lodData, unloadedLevelBias) {
    return worstLevel(lodData) + unloadedLevelBias;
}
export function nearestLevel(lodData, targetLevel) {
    const levels = lodData.curator_lods;
    const exactLevel = levels[targetLevel];
    if (exactLevel !== undefined)
        return exactLevel.lod;
    else if (targetLevel < 0)
        return 0;
    else
        return levels.length - 1;
}
export function alignMapOffset(image, level, mapOffset) {
    const tileSize = levelRatio(image.lodData, level);
    // TODO: align images to their highest LOD 2^level?
    mapOffset.x -= (mapOffset.x - image.mapPosition.x) % tileSize;
    mapOffset.y -= (mapOffset.y - image.mapPosition.y) % tileSize;
    return mapOffset;
}
export function hasLevel(mask, level) {
    return (mask & (1 << level)) !== 0;
}
export function addLevel(mask, level) {
    return mask | (1 << level);
}
export function removeLevel(mask, level) {
    return mask & ~(1 << level);
}
export function bestLevel(mask) {
    if (mask === 0)
        return +Infinity;
    for (let i = 0; i < 32; i++) {
        if (mask & (1 << i))
            return i;
    }
    return +Infinity;
}
export function iterateLevels(mask, callback) {
    for (let i = 0; i < 32; i++) {
        if (mask & (1 << i))
            callback(i);
    }
}
// tile pixels / planogram units
export function pixelRatioForLevel(level, tileSize) {
    return (TILE_CONTENT_SIZE * Math.pow(2, -level)) / tileSize;
}
export function createLodId(id, source) {
    return [id, source].join('-');
}
export function parseLodId(lodId) {
    const [id, source] = lodId.split('-');
    return { id: parseInt(id), source: source };
}
// TODO: split background into 4 items?
export function backgroundDataToLodItem(data) {
    var _a;
    const params = data.virtual_params;
    if (params === undefined || params === null)
        return undefined;
    const aspectRatio = data.original_width / data.original_height;
    const fitWidth = params.pagesWide;
    const fitHeight = Math.min(params.pagesHigh, params.pagesWide / aspectRatio);
    const lodLevels = [];
    for (let lod = 0; lod < ((_a = params.worstLod) !== null && _a !== void 0 ? _a : -1); ++lod) {
        const lodRatio = Math.pow(2, -lod);
        const gridSize = Math.max(params.pagesWide, params.pagesHigh) * lodRatio;
        const width = params.pagesWide * lodRatio;
        const height = params.pagesHigh * lodRatio;
        const textures = [];
        const minHeight = Math.floor((gridSize - height) * 0.5);
        const maxHeight = minHeight + height;
        for (let x = 0; x < gridSize; x++)
            for (let y = 0; y < gridSize; y++) {
                const isPadding = y < minHeight || maxHeight <= y || x >= width;
                if (isPadding)
                    textures.push(null);
                else
                    textures.push({ url: `${lod}-${x}-${y - minHeight}` });
            }
        const urlStart = `${data.tiles_path}textures`;
        lodLevels.push({
            textures,
            lod,
            url_start: urlStart,
        });
    }
    return {
        id: data.id,
        curator_lods: lodLevels,
        full_size: [params.pagesWide * params.pageSize, params.pagesHigh * params.pageSize],
        fit_size: [fitWidth * params.pageSize, fitHeight * params.pageSize],
        naturalResolution: [data.original_width, data.original_height],
        lods_version: 0,
    };
}
export function fallbackFitFullSize(data, lods) {
    if (!data.full_size) {
        const bestLod = lods.reduce((best, lod) => (lod.lod < best.lod ? lod : best));
        const pow2side = computeLevelMapSize(bestLod) * TILE_SIZE;
        data.full_size = [pow2side, pow2side];
    }
    if (!data.fit_size) {
        const fullSize = new Vector2(...data.full_size);
        const naturalResolution = data === null || data === void 0 ? void 0 : data.naturalResolution;
        const originalSize = naturalResolution ? new Vector2(...naturalResolution) : fullSize.clone();
        const aspectRatio = originalSize.x / originalSize.y;
        data.fit_size = new Vector2(Math.min(1, aspectRatio), 1 / Math.max(1, aspectRatio))
            .multiply(fullSize)
            .toArray();
    }
}
export function chunkIdForOffset(offset, resolution) {
    return offset.x + offset.y * resolution;
}
export function chunkOffsetForId(chunkId, resolution) {
    return new Vector2(chunkId % resolution, Math.floor(chunkId / resolution));
}
export function getImageTileByChunk(image, level, chunkId, tileMapResolution) {
    const lodLevel = image.lodData.curator_lods[level];
    if (isAtlas(lodLevel))
        return lodLevel.textures[0];
    const mapOffset = chunkOffsetForId(chunkId, tileMapResolution);
    const index = mapOffsetToIndex(image, mapOffset, level);
    const tile = lodLevel.textures[index];
    return tile;
}
export function coveredTiles(lodData, alignedChunkId, level, tileMapResolution) {
    const result = [];
    const rootOffset = chunkOffsetForId(alignedChunkId, tileMapResolution);
    const offset = new Vector2();
    const size = levelRatio(lodData, level);
    for (let x = 0; x < size; x++) {
        for (let y = 0; y < size; y++) {
            result.push(chunkIdForOffset(offset.set(x, y).add(rootOffset), tileMapResolution));
        }
    }
    return result;
}
export function getTileById(image, level, chunkId, tileMapResolution) {
    const lodLevel = image.lodData.curator_lods[level];
    if (isAtlas(lodLevel))
        return lodLevel.textures[0];
    const mapOffset = chunkOffsetForId(chunkId, tileMapResolution);
    const index = mapOffsetToIndex(image, mapOffset, level);
    const tile = lodLevel.textures[index];
    return tile;
}
