import { assertDefined } from './debug';
const registeredTypes = new Map();
export function registerType(name, t) {
    registeredTypes.set(name, t);
}
function replacer(_key, value) {
    if (typeof (value === null || value === void 0 ? void 0 : value.__className) !== 'function')
        return value;
    const className = value.__className();
    const registeredType = registeredTypes.get(className);
    assertDefined(registeredType, `Unknown types ${className}`);
    const result = registeredType.replacer(value);
    result.__className = value.__className();
    return result;
}
export function serializeMessage(message) {
    return JSON.stringify(message, replacer);
}
function reviver(_key, value) {
    if (typeof (value === null || value === void 0 ? void 0 : value.__className) !== 'string')
        return value;
    const registeredType = registeredTypes.get(value.__className);
    assertDefined(registeredType, `Unknown types ${value.__className}`);
    return registeredType.reviver(value);
}
export function unserializeMessage(message) {
    return JSON.parse(message, (reviver));
}
export function sendSerializedMessage(context, message) {
    context.postMessage(serializeMessage(message));
}
export function receiveSerializedMessages(context, callback) {
    context.onmessage = event => {
        const message = unserializeMessage(event.data);
        callback(message);
    };
}
