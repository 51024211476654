import { MathUtils, Vector2, Vector3 } from 'three';
import { SphereGeometry } from 'shared/geometries/SphereGeometry';
import planogramConfig from 'shared/config/PlanogramConfig';
import { calculateZXFlattenAngle } from 'shared/utils/GeometryUtils';
export class ViewableLimits {
    constructor(topLimit, bottomLimit) {
        this.topLimit = topLimit;
        this.bottomLimit = bottomLimit;
        this.topLimitPoint = this.calculateFlattenedLimitPoint(topLimit);
        this.bottomLimitPoint = this.calculateFlattenedLimitPoint(bottomLimit);
    }
    newAngle(camera, currentAngle, zoomAdjustAngle, tiltAmount) {
        this.updateTiltLimits(camera.position, camera.fov);
        if (currentAngle + zoomAdjustAngle + tiltAmount > this.maxTilt) {
            return this.maxTilt - zoomAdjustAngle;
        }
        if (currentAngle + zoomAdjustAngle + tiltAmount < this.minTilt) {
            return this.minTilt - zoomAdjustAngle;
        }
        return currentAngle + tiltAmount;
    }
    maxFOV(camPosition) {
        this.topLimitPoint = this.calculateFlattenedLimitPoint(this.topLimit);
        this.bottomLimitPoint = this.calculateFlattenedLimitPoint(this.bottomLimit);
        const points = this.limitPointsFromOrigin(camPosition);
        this.maxTilt = Math.PI - points.positive.angle();
        this.minTilt = Math.PI - points.negative.angle();
        return MathUtils.radToDeg(this.maxTilt - this.minTilt);
    }
    updateTiltLimits(camPosition, fov) {
        const fovOffset = MathUtils.degToRad(fov / 2.0);
        const point = this.limitPointsFromOrigin(camPosition);
        this.maxTilt = Math.PI - point.positive.angle() - fovOffset;
        this.minTilt = Math.PI - point.negative.angle() + fovOffset;
        if (this.maxTilt < this.minTilt) {
            this.maxTilt = (this.maxTilt + this.minTilt) / 2;
            this.minTilt = this.maxTilt;
        }
    }
    limitPoint(limitHeight) {
        const geom = new SphereGeometry(planogramConfig.ALPHA, planogramConfig.SHELL_RADIUS, planogramConfig.EQUATOR_RADIUS, 1, 1, SphereGeometry.calcAzimuthStartRadians(planogramConfig.WIDTH / 2, 1, planogramConfig.WIDTH), SphereGeometry.calcAzimuthLengthRadians(1, planogramConfig.WIDTH), planogramConfig.HEIGHT * 2, limitHeight, this.topLimit - this.bottomLimit, false);
        const point = new Vector3(geom.vertices[0], geom.vertices[1], geom.vertices[2]);
        geom.dispose();
        return point;
    }
    limitPointsFromOrigin(camPosition) {
        const clonedCamPosition = camPosition.clone();
        const flatCamPosition = calculateZXFlattenAngle(clonedCamPosition);
        clonedCamPosition.applyAxisAngle(new Vector3(0, 1, 0), flatCamPosition);
        const positiveLimitFromOrigin = new Vector2(this.topLimitPoint.x - clonedCamPosition.x, this.topLimitPoint.y - clonedCamPosition.y);
        const negativeLimitFromOrigin = new Vector2(this.bottomLimitPoint.x - clonedCamPosition.x, this.bottomLimitPoint.y - clonedCamPosition.y);
        return { positive: positiveLimitFromOrigin, negative: negativeLimitFromOrigin };
    }
    calculateFlattenedLimitPoint(limitHeight) {
        const limitPoint = this.limitPoint(limitHeight);
        const flatLimit = calculateZXFlattenAngle(limitPoint);
        limitPoint.applyAxisAngle(new Vector3(0, 1, 0), flatLimit - Math.PI);
        return limitPoint;
    }
}
