import { EventDispatcher, Vector2 } from 'three';
import { getEndTouchPosition, getPinchCenter, getTouchAsVector, getTouchDistance, } from 'shared/utils/InputUtils';
const CLICK_MARGIN_OF_ERROR = 5;
const DOUBLE_TAP_DELAY = 200;
const SCALE_SPEED = 2e-3;
export class TouchControls extends EventDispatcher {
    constructor() {
        super(...arguments);
        this.previousTouchDistance = 0;
        this.pinched = false;
        this.lastTapTimestamp = 0;
        this.doubleTapDragging = false;
        this.doubleTapLastPoint = new Vector2();
    }
    init(cameraControls, canvas) {
        this.cameraControls = cameraControls;
        this.setupEventListeners(canvas);
    }
    setupEventListeners(canvas) {
        var _a;
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        // TODO: don't assume canvas wrapper is the parent of canvas
        const canvasWrapper = (_a = canvas.parentElement) !== null && _a !== void 0 ? _a : undefined;
        this.canvasWrapper = canvasWrapper;
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.addEventListener('touchstart', this.onTouchStart, { passive: false });
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.addEventListener('touchend', this.onTouchEnd, { capture: true });
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.addEventListener('touchmove', this.onTouchMove, { passive: true });
    }
    onTouchStart(event) {
        this.startPointer = getTouchAsVector(event);
        this.doubleTapDragging = false;
        this.doubleTapLastPoint.copy(this.startPointer);
        let pointer = this.startPointer;
        if (event.timeStamp - this.lastTapTimestamp < DOUBLE_TAP_DELAY && event.touches.length === 1) {
            this.doubleTapDragging = true;
            this.previousTouchDistance = 0;
        }
        this.lastTapTimestamp = event.timeStamp;
        if (event.touches.length > 1) {
            this.previousTouchDistance = getTouchDistance(event.touches[0], event.touches[1]);
            pointer = getPinchCenter(event.touches[0], event.touches[1]);
        }
        this.pointerIntersection = this.cameraControls.sphereCamera.getMouseIntersection(pointer.x, pointer.y);
        this.previousEvent = event;
    }
    onTouchMove(event) {
        if (!this.startPointer) {
            return;
        }
        if (this.previousEvent && this.previousEvent.touches.length !== event.touches.length) {
            this.onTouchStart(event);
            return;
        }
        if (this.doubleTapDragging) {
            this.doubleTapZoom(event);
        }
        else if (event.touches.length > 1) {
            this.pinch(event);
            this.pinched = true;
        }
        else {
            const latestPointer = getTouchAsVector(event);
            const newIntersection = this.cameraControls.sphereCamera.getMouseIntersection(latestPointer.x, latestPointer.y);
            if (newIntersection && this.pointerIntersection) {
                this.dispatchEvent({ type: 'blur' });
                this.cameraControls.move(newIntersection, this.pointerIntersection);
            }
        }
        this.previousEvent = event;
    }
    onTouchEnd(event) {
        if (!this.startPointer) {
            return;
        }
        this.doubleTapDragging = false;
        if (this.isTap(event) && event.changedTouches.length > 0) {
            const touch = event.changedTouches[0];
            this.dispatchEvent({
                type: 'tap',
                point: new Vector2(touch.clientX, touch.clientY),
            });
            event.preventDefault();
        }
        else {
            event.stopPropagation();
        }
        if (event.touches.length === 0) {
            this.pinched = false;
            this.cameraControls.endCameraDrag();
            this.startPointer = undefined;
            this.previousEvent = undefined;
        }
    }
    doubleTapZoom(event) {
        if (this.startPointer === undefined)
            return;
        const endPoint = getTouchAsVector(event);
        const distance = SCALE_SPEED * (this.startPointer.y - endPoint.y);
        const diff = this.doubleTapLastPoint.clone().sub(endPoint);
        this.doubleTapLastPoint.copy(endPoint);
        if (Math.abs(diff.x) > Math.abs(diff.y)) {
            return;
        }
        const zoomScaleFactor = Math.pow(2, (distance - this.previousTouchDistance));
        this.cameraControls.zoomIntoPoint(this.startPointer, zoomScaleFactor);
        this.previousTouchDistance = distance;
    }
    pinch(event) {
        const currentTouchDistance = getTouchDistance(event.touches[0], event.touches[1]);
        const pinchCenter = getPinchCenter(event.touches[0], event.touches[1]);
        const zoomScaleFactor = this.previousTouchDistance / currentTouchDistance;
        this.cameraControls.zoomIntoPoint(pinchCenter, zoomScaleFactor);
        this.previousTouchDistance = currentTouchDistance;
        this.dispatchEvent({ type: 'blur' });
    }
    isTap(event) {
        var _a;
        if (this.pinched || event.touches.length > 0) {
            return false;
        }
        const currentPointer = getEndTouchPosition(event);
        const moveDistance = (_a = this.startPointer) === null || _a === void 0 ? void 0 : _a.distanceTo(currentPointer);
        if (moveDistance === undefined)
            return true;
        return moveDistance < CLICK_MARGIN_OF_ERROR;
    }
    dispose() {
        const canvasWrapper = this.canvasWrapper;
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.removeEventListener('touchstart', this.onTouchStart);
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.removeEventListener('touchend', this.onTouchEnd);
        canvasWrapper === null || canvasWrapper === void 0 ? void 0 : canvasWrapper.removeEventListener('touchmove', this.onTouchMove);
    }
}
