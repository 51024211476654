var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Vector2 } from 'three';
import { assertDefined } from '../utils/debug';
import { registerType } from '../utils/serialization.util';
import { addLevel, alignMapOffset, chunkIdForOffset, chunkOffsetForId, computeTileMapLocation, coveredTiles, getImageTileByChunk, imageChunkPlanogramPosition, levelRatio, mapOffsetToIndex, removeLevel, } from './helpers';
import { TRANSPARENT_TILE } from './TileMap';
export class CancelOperation extends Error {
    constructor() {
        super('Canceled');
    }
}
export class SlotMap {
    constructor() {
        this.slotMap = []; // level => (chunkId => slotId)
    }
    get(level, chunkId) {
        var _a;
        return (_a = this.slotMap[level]) === null || _a === void 0 ? void 0 : _a.get(chunkId);
    }
    set(level, chunkId, slot) {
        var _a;
        this.slotMap[level] = (_a = this.slotMap[level]) !== null && _a !== void 0 ? _a : new Map();
        this.slotMap[level].set(chunkId, slot);
    }
    isEmpty(level, chunkId) {
        var _a;
        return ((_a = this.slotMap[level]) === null || _a === void 0 ? void 0 : _a.get(chunkId)) === undefined;
    }
    pop(level, chunkId) {
        const slot = this.get(level, chunkId);
        if (slot !== undefined)
            this.slotMap[level].delete(chunkId);
        return slot;
    }
}
function addLevelToState(context, image, level, chunkId) {
    const state = context.currentState;
    const mapOffset = alignMapOffset(image, level, chunkOffsetForId(chunkId, context.tileMapResolution));
    const tileSize = levelRatio(image.lodData, level);
    const offset = new Vector2();
    for (let x = 0; x < tileSize; x++) {
        for (let y = 0; y < tileSize; y++) {
            offset.set(x, y).add(mapOffset);
            const id = chunkIdForOffset(offset, context.tileMapResolution);
            state[id] = addLevel(state[id], level);
            context.updateChunkTreeData(image, id, imageChunkPlanogramPosition(image, offset));
        }
    }
}
function removeLevelFromState(context, image, level, chunkId) {
    const state = context.currentState;
    const tileSize = levelRatio(image.lodData, level);
    const mapOffset = alignMapOffset(image, level, chunkOffsetForId(chunkId, context.tileMapResolution));
    const offset = new Vector2();
    for (let x = 0; x < tileSize; x++) {
        for (let y = 0; y < tileSize; y++) {
            offset.set(x, y).add(mapOffset);
            const id = chunkIdForOffset(offset, context.tileMapResolution);
            state[id] = removeLevel(state[id], level);
            context.updateChunkTreeData(image, id, imageChunkPlanogramPosition(image, offset));
        }
    }
}
export function cleanSlot(context, image, level, chunkId) {
    const mapOffset = context.tileMap.chunkOffset(chunkId);
    const index = mapOffsetToIndex(image, mapOffset, level);
    const lodLevel = image.lodData.curator_lods[level];
    const tile = lodLevel.textures[index];
    assertDefined(tile, 'Invalid tile index');
    const oldSlot = context.slotMap.pop(level, chunkId);
    if (oldSlot !== undefined)
        context.physicalTextures.freeSlot(oldSlot);
}
export class LoadOperation {
    constructor(imageId, level, chunkId) {
        this.level = level;
        this.chunkId = chunkId;
        this.canceled = false;
        this.name = 'load';
        this.imageId = imageId;
    }
    __className() {
        return 'LoadOperation';
    }
    causeChunkIds() {
        return [this.chunkId];
    }
    applyState(context) {
        addLevelToState(context, context.images.get(this.imageId), this.level, this.chunkId);
    }
    revertState(context) {
        removeLevelFromState(context, context.images.get(this.imageId), this.level, this.chunkId);
    }
    initialize(context) {
        const image = context.images.get(this.imageId);
        assertDefined(image, 'Image was removed during operation');
        const isEmpty = getImageTileByChunk(image, this.level, this.chunkId, context.tileMapResolution) === null;
        this.reservedSlots = isEmpty ? 0 : 1;
        this.affectedChunkIds = coveredTiles(image.lodData, this.chunkId, this.level, context.tileMapResolution);
    }
    preload(context) {
        const image = context.images.get(this.imageId);
        assertDefined(image, 'Image was removed during operation');
        const lodLevel = image.lodData.curator_lods[this.level];
        this.tile = getImageTileByChunk(image, this.level, this.chunkId, context.tileMap.resolution);
        assertDefined(this.tile, 'Invalid tile index');
        if (this.tile === null) {
            this.usedSlots = 0;
            return Promise.resolve();
        }
        this.tileUrl = `${context.cdnUrl}/${lodLevel.url_start}/${this.tile.url}.webp`;
        return context.textureCache.load(this.tileUrl).then(texture => {
            if (this.canceled)
                throw new CancelOperation();
            const storage = context.physicalTextures.storeTile(texture);
            this.slot = storage.slot;
            this.usedSlots = storage.cost;
            return storage.loaded;
        });
    }
    cancel(context) {
        const image = context.images.get(this.imageId);
        assertDefined(image, 'Image was removed during operation');
        this.canceled = true;
        context.textureCache.cancel(this.tileUrl);
        if (this.slot !== undefined) {
            context.physicalTextures.freeSlot(this.slot);
        }
    }
    execute(context) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const image = context.images.get(this.imageId);
        assertDefined(image, 'Image was removed during operation');
        const mapOffset = chunkOffsetForId(this.chunkId, context.tileMap.resolution);
        const index = mapOffsetToIndex(image, mapOffset, this.level);
        const tileSize = levelRatio(image.lodData, this.level);
        const tileMapLocation = computeTileMapLocation(image.lodData, this.level, index, image.mapPosition);
        if (this.tile === null) {
            context.tileMap.storeTileLocation(tileMapLocation, tileSize, TRANSPARENT_TILE);
            return;
        }
        assertDefined(this.slot, "preload didn't finish");
        context.slotMap.set(this.level, this.chunkId, this.slot);
        const tileSlotCoordinate = context.physicalTextures.tileSlotCoordinate(this.slot);
        // TODO: use best level from this.currentState?
        context.tileMap.storeTileLocation(tileMapLocation, tileSize, {
            textureIndex: context.physicalTextures.tileSlotTexture(this.slot),
            x: tileSlotCoordinate.x,
            y: tileSlotCoordinate.y,
            size: tileSize,
            ratio: (_b = (_a = this.tile.uv) === null || _a === void 0 ? void 0 : _a.width) !== null && _b !== void 0 ? _b : 1.0,
            atlasX: (_d = (_c = this.tile.uv) === null || _c === void 0 ? void 0 : _c.x) !== null && _d !== void 0 ? _d : 0.0,
            atlasY: 1.0 - ((_f = (_e = this.tile.uv) === null || _e === void 0 ? void 0 : _e.y) !== null && _f !== void 0 ? _f : 1.0) - ((_h = (_g = this.tile.uv) === null || _g === void 0 ? void 0 : _g.height) !== null && _h !== void 0 ? _h : 0.0),
        });
    }
    toString() {
        return `Load Operation Chunk Id: ${this.chunkId}`;
    }
}
registerType('LoadOperation', {
    replacer: (operation) => {
        return {
            imageId: operation.imageId,
            level: operation.level,
            chunkId: operation.chunkId,
            reservedSlots: operation.reservedSlots,
            usedSlots: operation.usedSlots,
            affectedChunkIds: operation.affectedChunkIds,
        };
    },
    reviver: function (value) {
        const operation = new LoadOperation(value.imageId, value.level, value.chunkId);
        operation.reservedSlots = value.reservedSlots;
        operation.usedSlots = value.usedSlots;
        operation.affectedChunkIds = value.affectedChunkIds;
        return operation;
    },
});
export class UnloadOperation {
    __className() {
        return 'UnloadOperation';
    }
    constructor(imageId, level, chunkId) {
        this.level = level;
        this.chunkId = chunkId;
        this.name = 'unload';
        this.imageId = imageId;
    }
    causeChunkIds() {
        return [this.chunkId];
    }
    applyState(context) {
        removeLevelFromState(context, context.images.get(this.imageId), this.level, this.chunkId);
    }
    revertState(context) {
        addLevelToState(context, context.images.get(this.imageId), this.level, this.chunkId);
    }
    initialize(context) {
        const image = context.images.get(this.imageId);
        this.reservedSlots = 0;
        this.affectedChunkIds = coveredTiles(image.lodData, this.chunkId, this.level, context.tileMapResolution);
    }
    preload(context) {
        var _a;
        const image = context.images.get(this.imageId);
        const mapOffset = context.tileMap.chunkOffset(this.chunkId);
        const index = mapOffsetToIndex(image, mapOffset, this.level);
        const lodLevel = image.lodData.curator_lods[this.level];
        const tile = lodLevel.textures[index];
        assertDefined(tile, 'Invalid tile index');
        const slot = context.slotMap.get(this.level, this.chunkId);
        const cantEmptySlot = (_a = (slot !== undefined && context.physicalTextures.slotUseCount(slot) > 1)) !== null && _a !== void 0 ? _a : false;
        this.usedSlots = context.slotMap.isEmpty(this.level, this.chunkId)
            ? 0
            : -1 + (cantEmptySlot ? 1 : 0);
        return Promise.resolve();
    }
    execute(context) {
        const image = context.images.get(this.imageId);
        const mapOffset = context.tileMap.chunkOffset(this.chunkId);
        const index = mapOffsetToIndex(image, mapOffset, this.level);
        const tileSize = levelRatio(image.lodData, this.level);
        const tileMapLocation = computeTileMapLocation(image.lodData, this.level, index, image.mapPosition);
        assertDefined(image, 'Image was removed during operation');
        cleanSlot(context, image, this.level, this.chunkId); // TODO: should it be here?
        context.tileMap.storeTileLocation(tileMapLocation, tileSize, TRANSPARENT_TILE);
    }
    cancel(_context) { }
    toString() {
        return `Unload Operation Chunk Id: ${this.chunkId}`;
    }
}
registerType('UnloadOperation', {
    replacer: (operation) => {
        return {
            imageId: operation.imageId,
            level: operation.level,
            chunkId: operation.chunkId,
            reservedSlots: operation.reservedSlots,
            usedSlots: operation.usedSlots,
            affectedChunkIds: operation.affectedChunkIds,
        };
    },
    reviver: function (value) {
        const operation = new UnloadOperation(value.imageId, value.level, value.chunkId);
        operation.reservedSlots = value.reservedSlots;
        operation.usedSlots = value.usedSlots;
        operation.affectedChunkIds = value.affectedChunkIds;
        return operation;
    },
});
export class CompositionOperation {
    __className() {
        return 'CompositionOperation';
    }
    constructor(name, operations) {
        this.operations = operations;
        this.name = name;
    }
    causeChunkIds() {
        return this.operations.flatMap(it => it.causeChunkIds());
    }
    applyState(context) {
        this.operations.forEach(it => it.applyState(context));
    }
    revertState(context) {
        this.operations.forEach(it => it.revertState(context));
    }
    initialize(context) {
        this.operations.forEach(it => it.initialize(context));
        this.reservedSlots = this.operations.reduce((sum, it) => (sum += it.reservedSlots), 0);
        this.affectedChunkIds = this.operations.reduce((union, it) => union.concat(union, it.affectedChunkIds), new Array());
    }
    preload(context) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(this.operations.map(it => it.preload(context)));
            this.usedSlots = this.operations.reduce((sum, it) => (sum += it.usedSlots), 0);
        });
    }
    execute(context) {
        this.operations.forEach(it => it.execute(context));
    }
    cancel(context) {
        this.operations.forEach(it => it.cancel(context));
    }
    toString() {
        return `Composed Operation {${this.operations.map(it => it.toString()).join(', ')}}`;
    }
}
registerType('CompositionOperation', {
    replacer: (operation) => {
        return {
            name: operation.name,
            reservedSlots: operation.reservedSlots,
            usedSlots: operation.usedSlots,
            affectedChunkIds: operation.affectedChunkIds,
            operations: operation.operations,
        };
    },
    reviver: function (value) {
        const operation = new CompositionOperation(value.name, value.operations);
        operation.reservedSlots = value.reservedSlots;
        operation.usedSlots = value.usedSlots;
        operation.affectedChunkIds = value.affectedChunkIds;
        return operation;
    },
});
