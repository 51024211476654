const forOwn = (obj, fn) => Object.keys(obj).forEach(key => fn(obj[key], key, obj));
const URL_VARIABLE_PLACEHOLDER = '{var}';
const HOST_ORIGIN_FORMATTED = `${window.location.protocol}//${window.location.hostname}`;
const HOST_PORT = window.location.port ? `:${window.location.port}` : '';
const ASSETS_PREFIX = !!REQUEST_PREFIX ? REQUEST_PREFIX : '';
const CURATOR_URL = `${HOST_ORIGIN_FORMATTED}${CURATOR_API_PORT}${CURATOR_API_PREFIX}`;
export const URLS = {
    DEV_PRODUCT_IMAGES_URL: 'https://spheres-dev.s3.eu-west-2.amazonaws.com/products/img',
    SPHERE_URL: `${HOST_ORIGIN_FORMATTED}${HOST_PORT}/${URL_VARIABLE_PLACEHOLDER}`,
    HOME_SPHERE: `${HOST_ORIGIN_FORMATTED}${HOST_PORT}/main`,
    LEGO_SEARCH_URL: `https://www.lego.com/search?q=${URL_VARIABLE_PLACEHOLDER}`,
    PLANOGRAM_LIST: `${CURATOR_URL}/planograms.json`,
    PLANOGRAM: `${CURATOR_URL}/planogram_versions/find.json?planogram_name={planogramName}&lang={lang}`,
    PRODUCT_VIEW: `${CURATOR_URL}/products/{code}/by_identifier.json?lang={lang}`,
    SHOPIFY_PRODUCT_VIEW: `${CURATOR_URL}/products/{code}/by_identifier.json?lang={lang}&currency_code={currency_code}`,
    SHOPIFY_COUNTRY: `https://${URL_VARIABLE_PLACEHOLDER}.myshopify.com/browsing_context_suggestions.json`,
    PRODUCT_DETAILS: `${CURATOR_URL}/product_details/${URL_VARIABLE_PLACEHOLDER}.json`,
    PRODUCTS: `${CURATOR_URL}/products/by_identifiers.json?lang=${URL_VARIABLE_PLACEHOLDER}`,
    SHOPIFY_PRODUCTS: `${CURATOR_URL}/products/by_identifiers.json?lang={lang}&currency_code={currency_code}`,
    CHECKOUT_PRODUCT_INFO: `${CURATOR_URL}/products/${URL_VARIABLE_PLACEHOLDER}/by_identifier/checkout.json`,
    PLANOGRAM_INFO: `${CURATOR_URL}/planograms/{planogramId}/planogram_versions/{versionId}/planogram_infos.json`,
    CUSTOM_FONTS: `${CURATOR_URL}/planograms/${URL_VARIABLE_PLACEHOLDER}/planogram_fonts.json`,
    CUSTOM_BUTTONS: `${CURATOR_URL}/buttons.json?lang=${URL_VARIABLE_PLACEHOLDER}`,
    SEARCH_URL: `${CURATOR_URL}/planogram_versions/{planogramId}/search.json?q={query}&per_page=60`,
    FILTER_URL: `${CURATOR_URL}/planogram_versions/{planogramId}/search.json?q={query}&per_page=60&filter_planogram_version_id={filterId}`,
    SEARCH_SETTINGS: `${CURATOR_URL}/planogram_versions/${URL_VARIABLE_PLACEHOLDER}/search_setting.json`,
    HEATMAP_URL: 'https://cdn-harry-dp.spheresdev.co.uk/cat.jpg',
    COOKIES_SETTINS: `${CURATOR_URL}/cookies_pop_ups.json?lang=${URL_VARIABLE_PLACEHOLDER}`,
    PURCHASING_FLOW_SETTINGS: `${CURATOR_URL}/purchasing_flow_settings.json`,
    SHOPIFY_CHECKOUT_CREATE: `${CURATOR_URL}/checkouts.json?lang={lang}&currency_code={currency_code}`,
    SHOPIFY_CHECKOUT_UPDATE: `${CURATOR_URL}/checkouts/cart_update.json?lang={lang}&currency_code={currency_code}`,
    SHOPIFY_CHECKOUT_ADD_ITEM: `${CURATOR_URL}/checkouts/add_cart_lines.json?lang={lang}&currency_code={currency_code}`,
    SHOPIFY_REMOVE_ITEM: `${CURATOR_URL}/checkouts/remove_cart_lines.json`,
    IDEAL_GET_CHECKOUT_INFO: `${CURATOR_URL}/carts/renew_cart_info.json?session_id={session_id}&lang={lang}&currency_code={currency_code}`,
    IDEAL_CHECKOUT_CREATE: `${CURATOR_URL}/carts.json`,
    IDEAL_CHECKOUT_ADD_PRODUCT: `${CURATOR_URL}/carts/add_product.json`,
    IDEAL_CHECKOUT_UPDATE_PRODUCT_QUANTITY: `${CURATOR_URL}/carts/update_product_quantity.json`,
    IDEAL_CHECKOUT_DELETE_PRODUCT: `${CURATOR_URL}/carts/delete_product.json`,
    SHOPIFY_CHECKOUT_UPDATE_CURRENCY: `${CURATOR_URL}/checkouts/change_currency.json?lang={lang}&currency_code={currency_code}`,
    SHOPIFY_VERIFY_MULTIPASS_TOKEN: `${CURATOR_URL}/checkouts/validate_multipass_token?sphere_multipass_tunnel_token=${URL_VARIABLE_PLACEHOLDER}`,
    SHOPIFY_GENERATE_MULTIPASS_LINK: `${CURATOR_URL}/checkouts/generate_multipass_link?sphere_multipass_tunnel_token={token}&shopify_link={link}`,
    USER_SIGN_IN: `${CURATOR_URL}/auth/sign_in.json?lang=${URL_VARIABLE_PLACEHOLDER}`,
    USER_SIGN_UP: `${CURATOR_URL}/auth/sign_up.json?lang=${URL_VARIABLE_PLACEHOLDER}`,
    USER_LOG_OUT: `${CURATOR_URL}/auth/log_out.json`,
    USER_RESET_PASSWORD: `${CURATOR_URL}/auth/reset_password.json`,
    USER_VERIFY_TOKEN: `${CURATOR_URL}/auth/verify_token.json?lang=${URL_VARIABLE_PLACEHOLDER}`,
    VIDEO_URL: `${CURATOR_URL}/videos/{id}.json`,
    PORTRAIT_VIEW_ROTATE_ICON: `${ASSETS_PREFIX}/assets/img/icons/portrait-view-rotate.svg`,
    CONTROL_BUTTON_ICON_FALLBACK: `${ASSETS_PREFIX}/assets/img/icons/control-btn-main-default.png`,
    VIDEO_CONTROLS_ICONS: `${ASSETS_PREFIX}/assets/img/icons/video_controls.png`,
    COOKIES_ICON: `${ASSETS_PREFIX}/assets/img/icons/cookies.svg`,
    CHECKMARK_ICON: `${ASSETS_PREFIX}/assets/img/icons/icon-check.svg`,
    ARROW_ICON: `${ASSETS_PREFIX}/assets/img/icons/icon-chevron-down.svg`,
    OVERLAY_REMOVE_ICON: `${ASSETS_PREFIX}/assets/img/icons/remove.png`,
    OVERLAY_COOKIE_ICON_1: `${ASSETS_PREFIX}/assets/img/icons/cookie-icon-1.png`,
    OVERLAY_COOKIE_ICON_2: `${ASSETS_PREFIX}/assets/img/icons/cookie-icon-2.png`,
    OVERLAY_COOKIE_ICON_3: `${ASSETS_PREFIX}/assets/img/icons/cookie-icon-3.png`,
    OVERLAY_COOKIE_ICON_4: `${ASSETS_PREFIX}/assets/img/icons/cookie-icon-4.png`,
    OVERLAY_TWITTER_ICON: `${ASSETS_PREFIX}/assets/img/icons/twitter.svg`,
    OVERLAY_WHATSAPP_ICON: `${ASSETS_PREFIX}/assets/img/icons/whatsapp.svg`,
    OVERLAY_TELEGRAM_ICON: `${ASSETS_PREFIX}/assets/img/icons/telegram.svg`,
    OVERLAY_VIBER_ICON: `${ASSETS_PREFIX}/assets/img/icons/viber.svg`,
    OVERLAY_CHECK_MARK: `${ASSETS_PREFIX}/assets/img/icons/check-mark.svg`,
    OVERLAY_COUNTDOWN_SECONDS_DIAL: `${ASSETS_PREFIX}/assets/img/icons/product-release-countdown-dial.svg`,
    OVERLAY_COUNTDOWN_ICON: `${ASSETS_PREFIX}/assets/img/icons/product-release-countdown-icon.svg`,
    OVERLAY_GALLERY_IMAGE_FALLBACK: `${ASSETS_PREFIX}/assets/img/gallery_fallback_image.png`,
    OVERLAY_GALLERY_VIDEO_IMAGE_FALLBACK: `${ASSETS_PREFIX}/assets/img/gallery_fallback_video.png`,
    ACCESSIBILITY_ICON: `${ASSETS_PREFIX}/assets/img/icons/icon-accessibility.svg`,
    UAL_LOGO: `${ASSETS_PREFIX}/assets/img/ual-icons/ual-logo-london-college-of-fashion-black.svg`,
    OVERLAY_SHARE_DEVICE_ICON: `${ASSETS_PREFIX}/assets/img/icons/share-device.svg`,
    OVERLAY_SHARE_LINK_ICON: `${ASSETS_PREFIX}/assets/img/icons/share_link.svg`,
    OVERLAY_SHARE_LINK_CHECK_ICON: `${ASSETS_PREFIX}/assets/img/icons/check.svg`,
    OVERLAY_SHARE_CLOSE_ICON: `${ASSETS_PREFIX}/assets/img/icons/share_close.svg`,
    OVERLAY_LEGO_ICON: `${ASSETS_PREFIX}/assets/img/icons/lego.svg`,
    OVERLAY_EBAY_ICON: `${ASSETS_PREFIX}/assets/img/icons/ebay.svg`,
    OVERLAY_AMAZON_ICON: `${ASSETS_PREFIX}/assets/img/icons/amazon.svg`,
    OVERLAY_PINS_ICON: `${ASSETS_PREFIX}/assets/img/icons/pins.svg`,
    OVERLAY_SOTHEBYS_SHIPPING_ICON: `${ASSETS_PREFIX}/assets/img/icons/icon-shipping.svg`,
    OVERLAY_SOTHEBYS_AUTHENTICITY_ICON: `${ASSETS_PREFIX}/assets/img/icons/icon-authenticity.svg`,
    OVERLAY_AJTRACEY_BRAN_ICON: `${ASSETS_PREFIX}/assets/img/icons/ajtracey-icon.svg`,
    OVERLAY_KHALILI_LOGO: `${ASSETS_PREFIX}/assets/img/logos/khalili-logo.png`,
    OVERLAY_HAJJ_LOGO: `${ASSETS_PREFIX}/assets/img/logos/hajj-logo.png`,
    SHOPIFY_SELECT_BOX_ICON: `${ASSETS_PREFIX}/assets/img/icons/caret-down.svg`,
    AUDIO_STOP_ICON: `${ASSETS_PREFIX}/assets/img/icons/audio-default-stop.svg`,
    AUDIO_MUTE_ICON: `${ASSETS_PREFIX}/assets/img/icons/audio-default-mute.svg`,
    AUDIO_UNMUTE_ICON: `${ASSETS_PREFIX}/assets/img/icons/audio-default-unmute.svg`,
    ERROR_404_MAIN_IMAGE: `${ASSETS_PREFIX}/assets/img/errors/404_main_image.png`,
    ERROR_404_BG_IMAGE: `${ASSETS_PREFIX}/assets/img/errors/404_bg_image.jpg`,
    ERROR_404_FORWARD_ARROW_IMAGE: `${ASSETS_PREFIX}/assets/img/errors/404_forward_arrow_image.svg`,
    ERROR_500_BG_IMAGE: `${ASSETS_PREFIX}/assets/img/errors/500_bg_image.jpg`,
    ERROR_500_BG_IMAGE_TABLET: `${ASSETS_PREFIX}/assets/img/errors/500_bg_image(tablet).jpg`,
    ERROR_500_BG_IMAGE_MOBILE: `${ASSETS_PREFIX}/assets/img/errors/500_bg_image(mobile).jpg`,
    SCENE_BG_IMAGE: `${ASSETS_PREFIX}/assets/img/bg-image.png`,
    SEARCH_PINS_NO_RESULT_IMAGE: `${ASSETS_PREFIX}/assets/img/icons/no-results_pins.png`,
    L10N_DICTIONARY: `${ASSETS_PREFIX}/assets/l10n/${URL_VARIABLE_PLACEHOLDER}.json`,
    L10N_LANGUAGES: `${CURATOR_URL}/planograms/${URL_VARIABLE_PLACEHOLDER}/planogram_languages.json`,
    L10N_LANGUAGE_ICON_EN: `${ASSETS_PREFIX}/assets/img/icons/l10n_en.svg`,
    L10N_LANGUAGE_ICON_DE: `${ASSETS_PREFIX}/assets/img/icons/l10n_de.svg`,
    L10N_LANGUAGE_ICON_FR: `${ASSETS_PREFIX}/assets/img/icons/l10n_fr.svg`,
    L10N_LANGUAGE_ICON_PL: `${ASSETS_PREFIX}/assets/img/icons/l10n_pl.svg`,
    ENTRANCE_GUIDE_ICON_ZOOM: `${ASSETS_PREFIX}/assets/img/icons/icon_zoom_pointer.svg`,
    ENTRANCE_GUIDE_ICON_ZOOM_NINJAGO: `${ASSETS_PREFIX}/assets/img/icons/icon_zoom_pointer_ninjago.svg`,
    ENTRANCE_GUIDE_ICON_HOLD_AND_DRAG: `${ASSETS_PREFIX}/assets/img/icons/icon_hold_n_drag.svg`,
    ENTRANCE_GUIDE_ICON_HOLD_AND_DRAG_NINJAGO: `${ASSETS_PREFIX}/assets/img/icons/icon_hold_n_drag_ninjago.svg`,
    ENTRANCE_GUIDE_ICON_ZOOM_INDICATOR: `${ASSETS_PREFIX}/assets/img/icons/zoom-indicator-point.svg`,
    ENTRANCE_GUIDE_ICON_DRAG_INDICATOR_ARROW: `${ASSETS_PREFIX}/assets/img/icons/drag-indicator-arrows.svg`,
    SHOPPING_CART_ICON: `${ASSETS_PREFIX}/assets/img/icons/icon-cart.svg`,
    DEFAULT_COOKIES_UPPER_ICON: `${ASSETS_PREFIX}/assets/img/icons/experience-cookies-default-icon.svg`,
    JOURNEY_SHARE_ICON: `${ASSETS_PREFIX}/assets/img/icons/journey-share.svg`,
    JOURNEY_PREV_ICON: `${ASSETS_PREFIX}/assets/img/icons/journey-prev.svg`,
    JOURNEY_PLAY_ICON: `${ASSETS_PREFIX}/assets/img/icons/journey-play.svg`,
    JOURNEY_PAUSE_ICON: `${ASSETS_PREFIX}/assets/img/icons/journey-pause.svg`,
    JOURNEY_NEXT_ICON: `${ASSETS_PREFIX}/assets/img/icons/journey-next.svg`,
    JOURNEY_EXIT_ICON: `${ASSETS_PREFIX}/assets/img/icons/journey-close.svg`,
    JOURNEY_WHATSAPP_ICON: `${ASSETS_PREFIX}/assets/img/icons/journey-whatsapp.svg`,
    JOURNEY_TWITTER_ICON: `${ASSETS_PREFIX}/assets/img/icons/journey-twitter.svg`,
    JOURNEY_TELEGRAM_ICON: `${ASSETS_PREFIX}/assets/img/icons/journey-telegram.svg`,
    JOURNEY_VIBER_ICON: `${ASSETS_PREFIX}/assets/img/icons/journey-viber.svg`,
    JOURNEY_LINK_ICON: `${ASSETS_PREFIX}/assets/img/icons/journey-link.svg`,
    JOURNEY_CLOSE_SHARE_ICON: `${ASSETS_PREFIX}/assets/img/icons/journey-close-share.svg`
};
export const CDN_RESOURCES = {
    PLANOGRAM: `${CDN_HOST}${URL_VARIABLE_PLACEHOLDER}`,
    SPHERE_TEXTURE: `${CDN_HOST}/{tilesPath}textures/{textureName}{extension}`,
    ITEM_TEXTURE: `${CDN_HOST}/{url}`,
    PRODUCT_IMAGE: `${CDN_HOST}${URL_VARIABLE_PLACEHOLDER}`,
    IMAGE: `${CDN_HOST}/images/${URL_VARIABLE_PLACEHOLDER}`
};
export class UrlUtils {
    static insertValueToUrl(url, value) {
        // tslint:disable-next-line`
        if (value === undefined) {
            throw new Error('Value cannot be undefined');
        }
        return url.replace(new RegExp(URL_VARIABLE_PLACEHOLDER), value);
    }
    static insertFewValuesToUrl(url, values) {
        let result = url;
        forOwn(values, (value, key) => {
            // tslint:disable-next-line
            result = result.replace(new RegExp('{' + key + '}'), value);
        });
        return result;
    }
    static getQueryValueFromUrl(fieldName) {
        try {
            const params = new URLSearchParams(window.location.search);
            const query = params.get(fieldName);
            return query && decodeURIComponent(query);
        }
        catch (e) {
            console.warn("Can't encode phrase");
        }
        return '';
    }
    static getUrlWithQueries(url, queries = {}) {
        const newUrl = new URL(url);
        Object.entries(queries).forEach(pair => newUrl.searchParams.set(...pair));
        return newUrl.toString();
    }
    static generateThumbnailURL(url, thumbnailPath) {
        if (!url) {
            return '';
        }
        if (!thumbnailPath) {
            return '';
        }
        const storageURL = url.substr(0, url.lastIndexOf('/'));
        return `${storageURL}/${thumbnailPath}`;
    }
    // https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
    static slugify(str) {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
        const p = new RegExp(a.split('').join('|'), 'g');
        return str
            .toString()
            .toLowerCase()
            .replace(/\s+/g, '-') // Replace spaces with -
            .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
            .replace(/&/g, '-and-') // Replace & with 'and'
            .replace(/[^\w\-]+/g, '') // Remove all non-word characters
            .replace(/\-\-+/g, '-') // Replace multiple - with single -
            .replace(/^-+/, '') // Trim - from start of text
            .replace(/-+$/, ''); // Trim - from end of text
    }
}
