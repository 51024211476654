var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Router from './router';
import { InputEventUtils } from './utils/input_event_utils';
import { Privacy } from './overlays/privacy';
import { ProductInfoLego } from './overlays/product-info/product-info-lego';
import { ProductInfoPins } from './overlays/product-info/product-info-pins';
import { ProductInfoService } from './api/services/product_info.service';
import { CookiesTable } from './overlays/cookies-table';
import { CUSTOM_OVERLAYS } from './shared/constants';
import { isCarouselOverlay, isContentOverlayAction, isLinkAction, isSingleImageAction, isSocialLinkAction, isVideoOverlayAction, SphereItemType } from 'shared/interfaces/planogram';
import { overlayEventsHandler, searchEventHandler, sphereEventHandler } from './custom_event_utils';
import { ImageOverlay } from './overlays/image-overlay';
import { VideoOverlay } from './overlays/video-overlay';
import { Metrics } from './metrics';
import { MATOMO_EVENT_NAMES } from './metric-events';
import { SocialFeedSource } from 'shared/interfaces/planogram';
import { ActionType } from 'shared/interfaces/planogram';
import { SEARCH_EVENT_NAMES as SEARCH_EVENTS, SPHERE_EVENT_NAMES, SPHERE_EVENT_NAMES as EVENTS } from './event-names';
import { IframeOverlay } from './overlays/iframe-overlay';
import { UrlUtils } from './api/urls';
import { AppUtils } from './utils/app_utils';
import { WebUtils } from './utils/web_utils';
import { ProductReleaseCountdownOverlay } from './overlays/product-release-countdown/product-release-countdown-overlay';
import { ProductInfoBase } from './overlays/product-info/product-info-base_overlay';
import { InfoOverlay } from './overlays/infos-base_overlay';
import { ProductInfoSelfridges } from './overlays/product-info/product-info-selfridges';
import { ProductInfoUal } from './overlays/product-info/product-info-ual';
import { CheckoutOverlay } from './overlays/checkout/checkout';
import { ProductInfoSothebys } from './overlays/product-info/product-info-sothebys';
import { ProductInfoAjTracey } from './overlays/product-info/product-info-ajtracey';
import { ProductInfoNinjago } from './overlays/product-info/product-info-ninjago';
import { ProductInfoDefault } from './overlays/product-info/product-info-default';
import { updateUIElementsVisibility } from './utils/ui_elements_utils';
import { ProductInfoKhalili } from './overlays/product-info/product-info-khalili';
import { ShopifyProductInfo } from './overlays/shopify-product-info/shopify-product_info';
import { ShopifyProductInfoService } from './api/services/shopify-product_info.service';
import { ShopifyHarryPotterProductInfo } from './overlays/shopify-product-info/harry-potter-overlays/shopify-harry-potter-product_info';
import { ContentOverlay } from './overlays/content-overlay';
import { ShareOverlay } from './overlays/share-overlay';
import { SocialContentOverlay } from './overlays/social-content_overlay';
import { ContentCarouselOverlay } from './overlays/content-carousel-overlay';
import { JourneyOverlay } from './overlays/journey-overlay';
import { IdealProductInfo } from './overlays/ideal-product-info/ideal-product_info';
export class Overlay {
    constructor(planogram, currencyService, shoppingCartService) {
        this.planogram = planogram;
        this.currencyService = currencyService;
        this.shoppingCartService = shoppingCartService;
        this.item = null;
        this.startY = 0;
        this._isActive = false;
        this._isJourneyOverlay = false;
        this.wrapper = document.getElementById('overlay');
        this.showCookiesOverlayBound = this.showCookiesOverlay.bind(this);
        this.showFullCookiesOverlayBound = this.showCookiesOverlay.bind(this, {
            isFullTable: true,
            cleanItem: true
        });
        this.hideCookieTableBound = this.hideCookieOverlay.bind(this);
        this.handleStartEventBound = this._handleStartEvent.bind(this);
        this.handleWheelEventBound = this._handleWheelEvent.bind(this);
        this.handleEndEventBound = this._handleEndEvent.bind(this);
        this.handleBlurEventBound = this.handleBlurEvent.bind(this);
        overlayEventsHandler.listen('showCookieTable', this.showCookiesOverlayBound);
        overlayEventsHandler.listen('showFullCookieTable', data => this.showFullCookiesOverlayBound(data));
        overlayEventsHandler.listen('hideCookieTable', this.hideCookieTableBound);
        ['mousedown', 'touchstart'].forEach(trigger => {
            this.wrapper.addEventListener(trigger, this.handleStartEventBound);
        });
        this.wrapper.addEventListener('wheel', this.handleWheelEventBound);
        ['mouseup', 'touchend'].forEach(trigger => {
            this.wrapper.addEventListener(trigger, this.handleEndEventBound);
        });
        window.addEventListener('blur', this.handleBlurEventBound);
        this.showVideoShare = this.showVideoShare.bind(this);
        sphereEventHandler.listen(EVENTS.VIDEO.SHARE, this.showVideoShare);
    }
    get isActive() {
        return this._isActive;
    }
    get isJourneyOverlay() {
        return this._isJourneyOverlay;
    }
    setRedirectToProduct(redirectToProduct) {
        this.redirectToProduct = redirectToProduct;
    }
    dispose() {
        var _a, _b;
        overlayEventsHandler.off('showCookieTable', this.showCookiesOverlayBound);
        overlayEventsHandler.off('showFullCookieTable', this.showFullCookiesOverlayBound);
        overlayEventsHandler.off('hideCookieTable', this.hideCookieTableBound);
        ['mousedown', 'touchstart'].forEach(trigger => {
            this.wrapper.removeEventListener(trigger, this.handleStartEventBound);
        });
        this.wrapper.removeEventListener('wheel', this.handleWheelEventBound);
        ['mouseup', 'touchend'].forEach(trigger => {
            this.wrapper.removeEventListener(trigger, this.handleEndEventBound);
        });
        window.removeEventListener('blur', this.handleBlurEventBound);
        sphereEventHandler.off(EVENTS.VIDEO.SHARE, this.showVideoShare);
        (_b = (_a = this.content) === null || _a === void 0 ? void 0 : _a.dispose) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
    hide(withNavigation = false) {
        if (this.content && this.content.dispose) {
            this.content.dispose();
        }
        searchEventHandler.emit(SEARCH_EVENTS.CLOSE_SEARCH);
        sphereEventHandler.emit(EVENTS.SPHERE.UNBLUR_CANVAS);
        this.wrapper.classList.add('is-hidden');
        this.wrapper.innerHTML = '';
        this.content = undefined;
        this._isActive = false;
        this._isJourneyOverlay = false;
        updateUIElementsVisibility(true);
        if (withNavigation) {
            Router.navigateToPlanogram(this.planogram.name);
        }
    }
    resize() {
        if (this.content.resize) {
            this.content.resize();
        }
    }
    isShowing() {
        return !!this.content;
    }
    showContentOverlay(item) {
        if (item.action === undefined || !isContentOverlayAction(item.action))
            throw new Error('Item must have a content overlay action');
        const blurGlCanvas = item.action.data.transparent ? 0 : this.planogram.blurScale;
        this._showContent(new ContentOverlay(this.wrapper, item.action.data.url, item, this.overlayOptions, this.closeOverlay.bind(this)), blurGlCanvas);
    }
    // TODO Simplify this logic in future
    showItem(item, overlayType = "gallery" /* PRODUCT_PAGE_ROUTES.GALLERY */, container, autoplayVideo, overlayOptions) {
        var _a;
        if (overlayType === "show" /* PRODUCT_PAGE_ROUTES.SHOW */) {
            return;
        }
        this.wrapper = container !== null && container !== void 0 ? container : document.getElementById('overlay');
        this.overlayOptions = Object.assign({}, overlayOptions);
        searchEventHandler.emit(SEARCH_EVENTS.CLOSE_SEARCH);
        if (this.isTheSameProductOpened(item) && overlayType !== "checkout" /* PRODUCT_PAGE_ROUTES.CHECKOUT */) {
            if (this.productOverlayType !== overlayType) {
                this.content.toggleView();
                this.productOverlayType = overlayType;
                this.productOverlayPrevState = overlayType;
            }
            return;
        }
        if (overlayType === "checkout" /* PRODUCT_PAGE_ROUTES.CHECKOUT */) {
            if (!this.isCheckoutPageOpened()) {
                this.showCheckoutOverlay(item, this.productOverlayPrevState);
            }
            return;
        }
        this.item = item;
        this.productOverlayType = overlayType;
        this.productOverlayPrevState = overlayType;
        if (item.action !== undefined && item.action.type !== ActionType.ProductOverlay) {
            if (item.action.type.startsWith('sphere-')) {
                return this.handleSphereNavigationAction(item);
            }
            return this.showCustomOverlay(item, autoplayVideo);
        }
        if (!item) {
            return;
        }
        this.showProductInfo(item, overlayType);
        const productName = UrlUtils.slugify((_a = item.name) !== null && _a !== void 0 ? _a : '');
        Metrics.storeTheEvent(item.planogram.name, 'open', `${MATOMO_EVENT_NAMES.WEBGL_OPEN_PRODUCT_OVERLAY}-${item.identifier}-${productName}`);
    }
    isTheSameProductOpened(item) {
        return item === this.item && this.content instanceof ProductInfoBase;
    }
    isCheckoutPageOpened() {
        return !this.item && this.content instanceof CheckoutOverlay;
    }
    handleSphereNavigationAction(item) {
        if (!item || !item.action) {
            return;
        }
        const sphereName = item.action.type.replace('sphere-', '');
        if (this.planogram.name === sphereName) {
            Metrics.storeTheEvent(item.planogram.name, 'click', MATOMO_EVENT_NAMES.WEBGL_CLICK_CENTRAL_CARD);
        }
        else {
            Metrics.storeTheEvent(this.planogram.name, 'click', `${MATOMO_EVENT_NAMES.WEBGL_CLICK_SPHERE}-${sphereName}`);
        }
        Router.navigateToPlanogram(sphereName);
    }
    showCustomOverlay(item, autoplayVideo) {
        var _a;
        const action = item.action;
        const itemName = WebUtils.getItemName(item, '-');
        switch (action.type) {
            case ActionType.AboutUs:
                this.showAboutUsOverlay();
                Metrics.storeTheEvent(this.planogram.name, 'open', `${MATOMO_EVENT_NAMES.WEBGL_CLICK_ABOUT_US}${itemName}`);
                break;
            case ActionType.ContactUs:
                this.showContactUsOverlay();
                Metrics.storeTheEvent(this.planogram.name, 'open', `${MATOMO_EVENT_NAMES.WEBGL_CLICK_CONTACT_US}${itemName}`);
                break;
            case ActionType.LegalNotice:
                this.showCopyrightOverlay();
                Metrics.storeTheEvent(this.planogram.name, 'open', `${MATOMO_EVENT_NAMES.WEBGL_OPEN_COPYRIGHT_OVERLAY}${itemName}`);
                break;
            case ActionType.PrivacyPolicy:
                this.showPrivacyOverlay();
                Metrics.storeTheEvent(this.planogram.name, 'open', `${MATOMO_EVENT_NAMES.WEBGL_OPEN_PRIVACY_OVERLAY}${itemName}`);
                break;
            case ActionType.CookiesPolicy:
                this.showCookiesPolicyOverlay();
                Metrics.storeTheEvent(item.planogram.name, 'open', `${MATOMO_EVENT_NAMES.WEBGL_OPEN_COOKIES_POLICY_OVERLAY}${itemName}`);
                break;
        }
        if (isLinkAction(action) && action.type === ActionType.IframeLink) {
            this.showIframeOverlay(action.data.url);
            Metrics.storeTheEvent(this.planogram.name, 'click', `${MATOMO_EVENT_NAMES.WEBGL_CLICK_IFRAME}${itemName}`);
        }
        if (isVideoOverlayAction(action)) {
            this.showVideoOverlay(action.data, autoplayVideo);
            Metrics.storeTheEvent(this.planogram.name, 'open', `${MATOMO_EVENT_NAMES.WEBGL_OPEN_VIDEO_OVERLAY}${itemName}`);
        }
        if (isContentOverlayAction(action)) {
            this.showContentOverlay(item);
            Metrics.storeTheEvent(this.planogram.name, 'open', `${MATOMO_EVENT_NAMES.WEBGL_CLICK_CONTENT_OVERLAY}-${action.data.iframeLink}`);
        }
        if (isSocialLinkAction(action)) {
            const source = (_a = action.data.source.toLowerCase()) !== null && _a !== void 0 ? _a : '';
            this.showSocialContentOverlay(action.data);
            Metrics.storeTheEvent(this.planogram.name, 'open', `${MATOMO_EVENT_NAMES.WEBGL_CLICK_SOCIAL_CONTENT_OVERLAY}-${source}${itemName}`);
        }
        if (isCarouselOverlay(action)) {
            this.showContentCarousel(item);
        }
        if (isSingleImageAction(action)) {
            this.showImage(item);
        }
    }
    showImage(item) {
        this.item = item;
        this._showContent(new ImageOverlay(this.wrapper, this.overlayOptions, item, this.closeOverlay.bind(this)));
        Metrics.storeTheEvent(this.planogram.name, 'click', `${MATOMO_EVENT_NAMES.WEBGL_OPEN_SINGLE_IMAGE_OVERLAY}${WebUtils.getItemName(item, '-')}`);
    }
    showContentCarousel(item) {
        this.item = item;
        this._showContent(new ContentCarouselOverlay(this.wrapper, item, this.overlayOptions, this.closeOverlay.bind(this)));
    }
    showJourneyOverlay(items, inputHandler, animationTime, autoplay) {
        return __awaiter(this, void 0, void 0, function* () {
            this.wrapper = document.getElementById('overlay');
            if (this.content instanceof JourneyOverlay || this.isJourneyOverlay) {
                return;
            }
            this.wrapper.classList.remove('is-hidden');
            Overlay.showLoader(this.wrapper);
            this._isJourneyOverlay = true;
            const productService = this.currencyService ? new ShopifyProductInfoService(this.currencyService) : new ProductInfoService();
            yield productService.getProducts(items.productIds);
            this._showContent(new JourneyOverlay(this.wrapper, items, inputHandler, animationTime, this.closeOverlay.bind(this), autoplay));
        });
    }
    showProductInfo(item, overlayType) {
        const countdownFunction = this.showProductReleaseCountdownOverlay.bind(this, item, overlayType);
        const checkoutFunction = this.showCheckoutOverlay.bind(this, item);
        let productOverlay;
        const productOverlayOptions = Object.assign({ initialOverlayToDisplay: overlayType, showCountdownOverlay: countdownFunction, showCheckoutOverlay: checkoutFunction }, this.overlayOptions);
        switch (true) {
            case AppUtils.isHarryPotterClient(item.planogram, item.planogram.clientName): {
                productOverlay = new ShopifyHarryPotterProductInfo(this.wrapper, item, this.planogram, this.redirectToProduct, productOverlayOptions, new ShopifyProductInfoService(this.currencyService), this.shoppingCartService, this.currencyService, this.closeOverlay.bind(this));
                break;
            }
            case AppUtils.isIdealEcommercePlatform(item.planogram): {
                productOverlay = new IdealProductInfo(this.wrapper, item, this.planogram, productOverlayOptions, new ShopifyProductInfoService(this.currencyService), this.shoppingCartService, this.currencyService, this.closeOverlay.bind(this));
                break;
            }
            case AppUtils.isShopifyEcommercePlatform(item.planogram): {
                productOverlay = new ShopifyProductInfo(this.wrapper, item, this.planogram, this.redirectToProduct, productOverlayOptions, new ShopifyProductInfoService(this.currencyService), this.shoppingCartService, this.currencyService, this.closeOverlay.bind(this));
                break;
            }
            case AppUtils.isWBClient(item.planogram.clientName): {
                productOverlay = new ProductInfoPins(this.wrapper, item, productOverlayOptions, new ProductInfoService(), this.closeOverlay.bind(this));
                break;
            }
            case AppUtils.isSelfridgesClient(item.planogram.clientName): {
                productOverlay = new ProductInfoSelfridges(this.wrapper, item, productOverlayOptions, new ProductInfoService(), this.closeOverlay.bind(this));
                break;
            }
            case AppUtils.isNinjagoClient(item.planogram.clientName): {
                productOverlay = new ProductInfoNinjago(this.wrapper, item, productOverlayOptions, new ProductInfoService(), this.closeOverlay.bind(this));
                break;
            }
            case AppUtils.isSothebysClient(item.planogram.clientName): {
                productOverlay = new ProductInfoSothebys(this.wrapper, item, productOverlayOptions, new ProductInfoService(), this.closeOverlay.bind(this));
                break;
            }
            case AppUtils.isLegoClient(item.planogram.clientName): {
                productOverlay = new ProductInfoLego(this.wrapper, item, productOverlayOptions, new ProductInfoService(), this.closeOverlay.bind(this));
                break;
            }
            case AppUtils.isAjTraceyClient(item.planogram.clientName): {
                productOverlay = new ProductInfoAjTracey(this.wrapper, item, productOverlayOptions, new ProductInfoService(), this.closeOverlay.bind(this));
                break;
            }
            case AppUtils.isKhaliliClient(item.planogram.clientName): {
                productOverlay = new ProductInfoKhalili(this.wrapper, item, productOverlayOptions, new ProductInfoService(), this.closeOverlay.bind(this));
                break;
            }
            case AppUtils.isUALClient(item.planogram.clientName): {
                productOverlay = new ProductInfoUal(this.wrapper, item, productOverlayOptions, new ProductInfoService(), this.closeOverlay.bind(this));
                break;
            }
            default: {
                productOverlay = new ProductInfoDefault(this.wrapper, item, productOverlayOptions, new ProductInfoService(), this.closeOverlay.bind(this));
            }
        }
        this._showContent(productOverlay);
        setTimeout(() => {
            this._isActive = true;
        }, 100);
    }
    showAboutUsOverlay() {
        this._showContent(new InfoOverlay(this.wrapper, this.planogram.id, this.planogram.versionId, CUSTOM_OVERLAYS.ABOUT_US, this.overlayOptions, this.closeOverlay.bind(this)));
    }
    showVideoOverlay(actionData, autoplay = true) {
        this._showContent(new VideoOverlay(this.wrapper, actionData, this.overlayOptions, this.closeOverlay.bind(this), autoplay));
    }
    showContactUsOverlay() {
        this._showContent(new InfoOverlay(this.wrapper, this.planogram.id, this.planogram.versionId, CUSTOM_OVERLAYS.CONTACT_US, this.overlayOptions, this.closeOverlay.bind(this)));
    }
    showCookiesOverlay(options) {
        if (options === null || options === void 0 ? void 0 : options.cleanItem) {
            this.item = null;
            this.productOverlayType = null;
        }
        const data = Object.assign(Object.assign({}, options), arguments[1]);
        this._showContent(new CookiesTable(this.wrapper, data, this.closeOverlay.bind(this)));
    }
    hideCookieOverlay() {
        const item = this.item;
        if (item && this.planogram.name === this.planogram.name) {
            this.showItem(this.item, this.productOverlayType);
        }
        else {
            this.closeOverlay(false);
        }
    }
    showPrivacyOverlay() {
        this._showContent(new Privacy(this.wrapper, this.planogram.id, this.planogram.versionId, CUSTOM_OVERLAYS.PRIVACY_POLICY, this.item, this.overlayOptions, this.closeOverlay.bind(this)));
    }
    showCookiesPolicyOverlay() {
        this._showContent(new InfoOverlay(this.wrapper, this.planogram.id, this.planogram.versionId, CUSTOM_OVERLAYS.COOKIES_POLICY, this.overlayOptions, this.closeOverlay.bind(this)));
    }
    showCopyrightOverlay() {
        this._showContent(new InfoOverlay(this.wrapper, this.planogram.id, this.planogram.versionId, CUSTOM_OVERLAYS.COPYRIGHT, this.overlayOptions, this.closeOverlay.bind(this)));
    }
    showIframeOverlay(url) {
        this._showContent(new IframeOverlay(this.wrapper, url, this.overlayOptions, this.closeOverlay.bind(this)));
    }
    showProductReleaseCountdownOverlay(item, productInfoOverlayType = 'details', productReleaseData) {
        const showProductInfoOverlayCallback = this.showProductInfo.bind(this, item, productInfoOverlayType);
        this._showContent(new ProductReleaseCountdownOverlay(this.wrapper, { productReleaseData, showProductInfoOverlayCallback }, this.closeOverlay.bind(this)));
    }
    showCheckoutOverlay(item, productInfoOverlayType = 'gallery', product = null) {
        this._showContent(new CheckoutOverlay(this.wrapper, { product, productInfoOverlayType, item }, new ProductInfoService(), this.closeOverlay.bind(this)));
    }
    showSocialContentOverlay(actionData) {
        var _a;
        const url = AppUtils.generateSocialContentLink(actionData);
        const { clientSocialMedias } = this.planogram;
        const socialProvider = clientSocialMedias === null || clientSocialMedias === void 0 ? void 0 : clientSocialMedias.find(media => (media === null || media === void 0 ? void 0 : media.social_media_name) === actionData.source);
        const blurCanvas = socialProvider === null || socialProvider === void 0 ? void 0 : socialProvider.blur;
        const showLoader = (_a = socialProvider === null || socialProvider === void 0 ? void 0 : socialProvider.show_loader) !== null && _a !== void 0 ? _a : false;
        if (actionData.source === SocialFeedSource.X) {
            this._showContent(new SocialContentOverlay(this.wrapper, actionData.contentValue, showLoader, this.overlayOptions, this.closeOverlay.bind(this)), blurCanvas);
            return;
        }
        this._showContent(new IframeOverlay(this.wrapper, url, this.overlayOptions, this.closeOverlay.bind(this), actionData.source, actionData.contentType, showLoader), blurCanvas);
    }
    _showContent(content, blurCanvas) {
        this.content = content;
        sphereEventHandler.emit(EVENTS.SPHERE.BLUR_CANVAS, { kernelSize: blurCanvas });
        updateUIElementsVisibility(false);
        this.wrapper.classList.remove('is-hidden');
        sphereEventHandler.emit(EVENTS.OVERLAY.SHOW_CONTENT, { content });
    }
    _handleStartEvent(event) {
        if (!event.target.closest('.with-propagation')) {
            event.stopPropagation();
        }
        this.startEvent = event;
        if (event.type === 'touchstart') {
            const e = event;
            this.startY = e.touches.length && e.touches[0].pageY;
        }
    }
    static showLoader(container) {
        container.innerHTML = `
      <span class="loading-icon absolute is-loading icon-large fill-green">
        <svg viewBox="0 0 32 32">
          <path d="M32 16c-0.040-2.089-0.493-4.172-1.331-6.077-0.834-1.906-2.046-3.633-3.533-5.060-1.486-1.428-3.248-2.557-5.156-3.302-1.906-0.748-3.956-1.105-5.981-1.061-2.025 0.040-4.042 0.48-5.885 1.292-1.845 0.809-3.517 1.983-4.898 3.424s-2.474 3.147-3.193 4.994c-0.722 1.846-1.067 3.829-1.023 5.79 0.040 1.961 0.468 3.911 1.254 5.694 0.784 1.784 1.921 3.401 3.316 4.736 1.394 1.336 3.046 2.391 4.832 3.085 1.785 0.697 3.701 1.028 5.598 0.985 1.897-0.040 3.78-0.455 5.502-1.216 1.723-0.759 3.285-1.859 4.574-3.208 1.29-1.348 2.308-2.945 2.977-4.67 0.407-1.046 0.684-2.137 0.829-3.244 0.039 0.002 0.078 0.004 0.118 0.004 1.105 0 2-0.895 2-2 0-0.056-0.003-0.112-0.007-0.167h0.007zM28.822 21.311c-0.733 1.663-1.796 3.169-3.099 4.412s-2.844 2.225-4.508 2.868c-1.663 0.646-3.447 0.952-5.215 0.909-1.769-0.041-3.519-0.429-5.119-1.14-1.602-0.708-3.053-1.734-4.25-2.991s-2.141-2.743-2.76-4.346c-0.621-1.603-0.913-3.319-0.871-5.024 0.041-1.705 0.417-3.388 1.102-4.928 0.683-1.541 1.672-2.937 2.883-4.088s2.642-2.058 4.184-2.652c1.542-0.596 3.192-0.875 4.832-0.833 1.641 0.041 3.257 0.404 4.736 1.064 1.48 0.658 2.82 1.609 3.926 2.774s1.975 2.54 2.543 4.021c0.57 1.481 0.837 3.064 0.794 4.641h0.007c-0.005 0.055-0.007 0.11-0.007 0.167 0 1.032 0.781 1.88 1.784 1.988-0.195 1.088-0.517 2.151-0.962 3.156z"></path>
        </svg>
      </span>`;
    }
    _handleWheelEvent(event) {
        event.stopPropagation();
    }
    _handleMoveEvent(event) {
        const selectors = [
            '.text-wrapper',
            '.text-content',
            '.app-product-description-text',
            '.app-product-fields',
            '.app-product-description-content',
            '.cookies-wrapper',
            '.app-product-scrollable-container',
            '.allow-scroll'
        ];
        const element = event.target.closest(selectors.join(', '));
        event.stopPropagation();
        if (!element) {
            event.preventDefault();
        }
        if (event.type === 'touchmove') {
            const endY = event.touches.length && event.touches[0].pageY;
            if (element && WebUtils.isScrolledToTheEnd(element, this.startY, endY) && event.cancelable) {
                event.preventDefault();
            }
        }
    }
    _handleEndEvent(event) {
        if (!this.isShowing()) {
            return;
        }
        const isClick = this.startEvent && InputEventUtils.isClick(this.startEvent, event);
        if (isClick && (event.target === this.wrapper || this.content.handleClick(event))) {
            event.preventDefault();
            this.closeOverlay();
        }
        this.startEvent = undefined;
    }
    closeOverlay(withNavigation = true) {
        // TODO Add removing of listeners after close to every overlay
        this.hide(withNavigation);
        sphereEventHandler.emit(EVENTS.OVERLAY.CLOSE_CONTENT, Object.assign({}, this.item));
        this.productOverlayType = null;
        this.overlayOptions = undefined;
        this.item = null;
        this.wrapper = document.getElementById('overlay');
    }
    handleLeftKey() {
        if (this.content && this.content.handleLeftKey) {
            this.content.handleLeftKey();
        }
    }
    handleRightKey() {
        if (this.content && this.content.handleRightKey) {
            this.content.handleRightKey();
        }
    }
    handleBlurEvent() {
        if (document.activeElement.tagName === 'IFRAME') {
            overlayEventsHandler.emit(SPHERE_EVENT_NAMES.OVERLAY.OVERLAY_USER_ACTION);
        }
    }
    showVideoShare(video) {
        const url = Router.generateShareableLink(Router.generateItemUrl(video.id, SphereItemType.Video, video.planogram.name, true));
        this._showContent(new ShareOverlay(this.wrapper, this.planogram, url, this.closeOverlay.bind(this)));
    }
    static showSeoTitle(container, title) {
        const titleWrapper = document.createElement('div');
        const header = document.createElement('h5');
        titleWrapper.classList.add('overlay-item-title');
        header.innerText = title;
        titleWrapper.append(header);
        container === null || container === void 0 ? void 0 : container.append(titleWrapper);
    }
}
