import { Texture } from 'three';
export class TextureCache {
    constructor() {
        this.cancelations = new Map();
        this.cache = new Map();
    }
    load(url) {
        const cached = this.cache.get(url);
        if (cached)
            return cached;
        const img = document.createElement('img');
        img.crossOrigin = 'anonymous';
        img.src = url;
        const cancelation = new AbortController();
        this.cancelations.set(url, cancelation);
        const request = new Promise((resolve, reject) => {
            cancelation.signal.onabort = () => {
                img.src = '';
                reject(new Error('Canceled'));
            };
            img.onload = () => {
                this.cancelations.delete(url);
                const texture = new Texture(img);
                texture.generateMipmaps = false;
                texture.needsUpdate = true;
                resolve(texture);
            };
            img.onerror = (e) => typeof e === 'string' ? new Error(e) : new Error(`Failed to load ${url}`);
        });
        this.cache.set(url, request);
        request.finally(() => {
            const cached = this.cancelations.get(url);
            if (cached === cancelation)
                this.cancelations.delete(url);
        });
        return request;
    }
    cancel(url) {
        var _a;
        (_a = this.cancelations.get(url)) === null || _a === void 0 ? void 0 : _a.abort();
        this.cancelations.delete(url);
    }
    // TODO: use it to reduce RAM usage on large spheres
    unload(url) {
        var _a, _b;
        (_a = this.cancelations.get(url)) === null || _a === void 0 ? void 0 : _a.abort();
        (_b = this.cache.get(url)) === null || _b === void 0 ? void 0 : _b.then(t => t.dispose());
        this.cancelations.delete(url);
        this.cache.delete(url);
    }
    dispose() {
        Array.from(this.cancelations.values()).forEach(c => c.abort());
        Array.from(this.cache.values()).forEach(p => p.then(t => t.dispose()));
    }
}
