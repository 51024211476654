import EventManager from './EventManager';
var EventNames;
(function (EventNames) {
    EventNames[EventNames["ZOOM"] = 0] = "ZOOM";
    EventNames[EventNames["MOVE"] = 1] = "MOVE";
})(EventNames || (EventNames = {}));
const heatMapEventManager = new EventManager();
export const HeatMapEvents = {
    ZOOM: heatMapEventManager.createEvent(EventNames.ZOOM),
    MOVE: heatMapEventManager.createEvent(EventNames.MOVE),
};
export default heatMapEventManager;
