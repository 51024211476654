var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass';
import { BlurPass } from 'postprocessing';
import { Clock, Color, Raycaster, Scene, Vector2, WebGLRenderer } from 'three';
import { normalizeMouse } from './utils/math_utils';
import LodProvider from 'shared/lod/LodProvider';
import { SphereItem } from './sphere_item';
import { LodSource } from 'shared/lod/interfaces';
import { isLodItem } from './utils/planogram_utils';
import PlanogramPoint from 'shared/utils/PlanogramPoint';
import loadingProgress, { LOADING_STAGES } from './api/services/loading_progress.service';
import { backgroundDataToLodItem, createLodId, parseLodId, skipAtlasLevels } from 'shared/lod/helpers';
import { disposeObject3D } from './utils/disposeThree';
import { SphereItemType } from 'shared/interfaces/planogram';
import { BrowserUtils } from './utils/browser_utils';
import { debugFloatPrameter } from 'shared/utils/debug';
import SphereCamera from 'shared/renderingEngine/SphereCamera';
import { ViewableLimits } from './viewable_limits';
import cameraConfig from 'shared/config/CameraConfig';
const BLUR_RESOLUTION_WIDTH = 960;
const BLUR_KERNEL_SIZE = 5;
const MAX_LOD_LOADING_DURATION = debugFloatPrameter('MAX_LOD_LOADING_DURATION', 1700);
const BACKGROUND_RENDER_ORDER = -1; // SphereItems have render order 0
// using full device pixel ratio can put the renderer resolution over the max allowed texture size on some devices, which crashes post-processing
function limitDevicePixelRatio(renderer) {
    const maxTextureSize = renderer.capabilities.maxTextureSize;
    const size = renderer.getSize(new Vector2());
    return Math.min(window.devicePixelRatio, maxTextureSize / size.x, maxTextureSize / size.y);
}
function getItemId(item) {
    return item.id;
}
function getLodId(item) {
    var _a, _b;
    const pictureData = item.data;
    const id = (_b = (_a = pictureData.picture) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : pictureData.id;
    if (pictureData.isFigma) {
        return createLodId(id, LodSource.FIGMA);
    }
    else {
        return createLodId(id, LodSource.DEFAULT);
    }
}
function pickDeviceLimits() {
    if (BrowserUtils.detectWebkitInAppBrowser() && BrowserUtils.iPhoneMachineId() < 12.1) {
        return {
            physicalTextureCount: 3,
            physicalTextureResolution: 2048
        };
    }
    else if (BrowserUtils.isMobileSafari()) {
        return {
            physicalTextureCount: 4
        };
    }
    else {
        return {
            physicalTextureCount: 7
        };
    }
}
function measureCanvasSize() {
    // TODO: set canvas size with styles instead, measure renderer.domElement's size here
    return new Vector2(window.innerWidth, window.innerHeight);
}
export default class CanvasRenderer {
    get capabilities() {
        return this.renderer.capabilities;
    }
    get domElement() {
        return this.renderer.domElement;
    }
    constructor(canvas, planogram) {
        var _a;
        this.planogram = planogram;
        this.passes = {
            renderPass: undefined,
            blurPass: undefined,
            smaaPass: undefined
        };
        this.isPaused = false;
        this.clock = new Clock();
        this.viewportListeners = [];
        this.renderListeners = [];
        {
            this.scene = new Scene();
            this.scene.background = new Color(...((_a = planogram.backgroundColor) !== null && _a !== void 0 ? _a : []));
        }
        this.renderer = new WebGLRenderer({
            canvas,
            antialias: false,
            alpha: true,
            stencil: false,
            depth: true
        });
        this.renderer.autoClear = false;
        {
            this.viewableLimits = new ViewableLimits(planogram);
            const startPoint = (this.planogram.startPoint / this.planogram.width) * 360.0;
            this.camera = new SphereCamera(measureCanvasSize(), this.planogram.cameraPosition, startPoint);
        }
        {
            this.composer = new EffectComposer(this.renderer);
            this.passes.renderPass = new RenderPass(this.scene, this.camera.camera);
            this.passes.renderPass.clear = false;
            this.composer.addPass(this.passes.renderPass);
            this.passes.smaaPass = new SMAAPass(window.innerWidth, window.innerHeight);
            this.composer.addPass(this.passes.smaaPass);
            this.passes.blurPass = new BlurPass({
                width: BLUR_RESOLUTION_WIDTH
            });
            this.composer.addPass(this.passes.blurPass);
        }
        this.updateRendererSize();
        {
            this.cameraListener = () => {
                const planogramToCanvas = this.viewableLimits.getLimitHeight() / canvas.height;
                this.lodProvider.updateCamera(this.camera.planogramPoint(), this.camera.zoomRatio() / planogramToCanvas);
                const viewport = this.camera.computeViewport();
                this.viewportListeners.forEach(it => it(viewport));
            };
            this.camera.addEventListener('Updated', this.cameraListener);
        }
        this.lodProvider = new LodProvider(this.renderer, Object.assign({ cdnUrl: CDN_HOST }, pickDeviceLimits()));
    }
    makeBackgroundItem(id, imageId, backgroundImage) {
        const imageData = {
            id: imageId,
            image_name: id,
            name: id,
            description: '',
            lods_version: 0,
            naturalResolution: [backgroundImage.original_width, backgroundImage.original_height],
            url: backgroundImage.url,
            thumbnails: backgroundImage.thumbnails,
            opacity: 1.0,
            fit_size: [backgroundImage.original_width, backgroundImage.original_height],
            full_size: [backgroundImage.original_width, backgroundImage.original_height]
        };
        const planogramSize = this.planogram.size();
        const backgroundItem = new SphereItem({
            id: id,
            x: 0,
            layer: 0,
            y: -planogramSize.y,
            type: SphereItemType.Image,
            width: planogramSize.x,
            height: planogramSize.x,
            data: imageData,
            renderOrder: BACKGROUND_RENDER_ORDER,
            lods: [], // otherwise SphereItem constructor makes it invisible
            visible: true
        }, this.planogram);
        return backgroundItem;
    }
    addItems(itemsGroup) {
        return __awaiter(this, void 0, void 0, function* () {
            this.scene.add(itemsGroup);
            const images = new Map();
            const lodData = new Map();
            itemsGroup.items.forEach(item => {
                var _a, _b;
                if (!isLodItem(item.itemData))
                    return;
                item.material = this.lodProvider.getMaterial(getItemId(item));
                const itemData = item.itemData;
                const lodId = getLodId(item);
                const itemId = getItemId(item);
                images.set(itemId, {
                    lodId,
                    position: new PlanogramPoint(item.getViewportCenter()),
                    size: item.getSize(),
                    naturalResolution: new Vector2(...(_a = itemData.data) === null || _a === void 0 ? void 0 : _a.naturalResolution),
                    opacity: (_b = item.data.opacity) !== null && _b !== void 0 ? _b : 1
                });
                const filteredLods = skipAtlasLevels(itemData.lods).filter((lod, i) => {
                    function atlasSize(lod) {
                        var _a, _b, _c;
                        return (_c = (_b = (_a = lod.textures[0]) === null || _a === void 0 ? void 0 : _a.uv) === null || _b === void 0 ? void 0 : _b.width) !== null && _c !== void 0 ? _c : 1.0;
                    }
                    if (i === 0)
                        return true;
                    if (atlasSize(lod) === 1.0)
                        return true;
                    // TODO: ask BE to filter out identical atlas LOD levels
                    return atlasSize(itemData.lods[i - 1]) !== atlasSize(lod);
                });
                lodData.set(lodId, {
                    id: parseLodId(lodId).id,
                    curator_lods: filteredLods,
                    fit_size: itemData.data.fit_size,
                    full_size: itemData.data.full_size,
                    naturalResolution: itemData.data.naturalResolution,
                    lods_version: itemData.data.lods_version
                });
            });
            const backgroundImage = this.planogram.background_images[0];
            if (backgroundImage !== undefined) {
                const BACKGROUND_ID = 'generated-background';
                const BACKGROUND_IMAGE_ID = -1;
                const backgroundItem = this.makeBackgroundItem(BACKGROUND_ID, BACKGROUND_IMAGE_ID, backgroundImage);
                backgroundItem.material = this.lodProvider.getMaterial(getItemId(backgroundItem));
                yield backgroundItem.createMesh(this.capabilities);
                this.scene.add(backgroundItem.object3D);
                const lodId = createLodId(backgroundImage.id, LodSource.BACKGRDOUND);
                images.set(BACKGROUND_ID, {
                    lodId,
                    position: new PlanogramPoint(backgroundItem.getViewportCenter()),
                    size: this.planogram.size(),
                    naturalResolution: new Vector2(backgroundImage.original_width, backgroundImage.original_height),
                    opacity: 1
                });
                lodData.set(lodId, backgroundDataToLodItem(backgroundImage));
            }
            this.lodProvider.updateImageList(images, (ids) => Promise.resolve(ids.map(id => lodData.get(id))));
        });
    }
    preloadLod() {
        return new Promise(resolve => {
            this.start(); // TODO: just calling this.lodProvider.update repeatedly should be enough, but it doesn't work, and this.render doesn't either
            let timeout;
            const finish = () => {
                loadingProgress.progressStage(LOADING_STAGES.TILES_FOR_LOD, 1);
                this.lodProvider.removeLoadedListener(finish);
                this.stop();
                clearTimeout(timeout);
                resolve();
            };
            timeout = setTimeout(finish, MAX_LOD_LOADING_DURATION);
            this.lodProvider.addLoadedListener(finish);
            // normally LOD updates are spread over frames to minimize FPS hit
            // during initial loading it doesn't matter, so we update as often as possible to speed up loading
        });
    }
    blur(scale) {
        this.passes.blurPass.enabled = scale > 0;
        this.passes.blurPass.kernelSize = BLUR_KERNEL_SIZE;
        this.passes.blurPass.scale = scale !== null && scale !== void 0 ? scale : 0.12;
    }
    start() {
        this.camera.canvasSize = measureCanvasSize();
        this.renderer.setAnimationLoop(() => this.render());
    }
    stop() {
        this.renderer.setAnimationLoop(null);
    }
    pauseLodUpdates(state) {
        this.isPaused = state;
    }
    updateRendererSize() {
        const canvasSize = measureCanvasSize();
        this.camera.canvasSize = canvasSize;
        const pixelRatio = limitDevicePixelRatio(this.renderer);
        this.renderer.setSize(canvasSize.x, canvasSize.y);
        this.renderer.setPixelRatio(pixelRatio);
        this.composer.setSize(canvasSize.x, canvasSize.y);
        this.composer.setPixelRatio(pixelRatio);
        this.passes.smaaPass.setSize(canvasSize.x, canvasSize.y);
    }
    resize() {
        this.updateRendererSize();
        this.renderer.setRenderTarget(null);
        this.camera.zoomTo(cameraConfig.FOV_DEFAULT);
        this.composer.render();
    }
    onViewportChange(callback) {
        this.viewportListeners.push(callback);
    }
    render() {
        if (!this.isPaused) {
            this.lodProvider.update();
        }
        const dt = this.clock.getDelta();
        this.composer.render();
        this.renderListeners.forEach(it => it(dt));
    }
    onRender(callback) {
        this.renderListeners.push(callback);
    }
    getInteractableObjectAtScreenCoordinate(x, y) {
        var _a;
        const coords = normalizeMouse(x, y);
        const raycaster = new Raycaster();
        raycaster.setFromCamera(coords, this.camera.camera);
        const intersects = raycaster.intersectObjects(this.scene.children, true);
        // Filter intersection that are closer than the near clipping plane.
        // Since the camera, and thus the origin of the raycaster, is located outside of the sphere
        // it can happens that when raycasting items on the opposite side of the sphere are also intersected.
        const topIntersection = intersects
            .filter(i => i.distance > cameraConfig.NEAR_PLANE)
            .sort((a, b) => {
            return b.object.renderOrder - a.object.renderOrder;
        })[0];
        // Layer 2 is reserved for elements reacting to input
        const hasInput = (_a = topIntersection === null || topIntersection === void 0 ? void 0 : topIntersection.object.layers.isEnabled(2)) !== null && _a !== void 0 ? _a : false;
        if (!hasInput) {
            return { mesh: undefined, point: undefined };
        }
        return { mesh: topIntersection.object, point: topIntersection.point };
    }
    dispose() {
        this.lodProvider.dispose();
        this.stop();
        this.viewportListeners.splice(0, this.viewportListeners.length);
        this.renderListeners.splice(0, this.renderListeners.length);
        this.camera.removeEventListener('Updated', this.cameraListener);
        disposeObject3D(this.scene);
    }
}
