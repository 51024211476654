import EventManager from './EventManager';
var EventNames;
(function (EventNames) {
    EventNames[EventNames["ZOOM"] = 0] = "ZOOM";
    EventNames[EventNames["DRAG_CAMERA_START"] = 1] = "DRAG_CAMERA_START";
    EventNames[EventNames["DRAG_CAMERA"] = 2] = "DRAG_CAMERA";
    EventNames[EventNames["ROTATION_START"] = 3] = "ROTATION_START";
})(EventNames || (EventNames = {}));
const camera3DEventManager = new EventManager();
export const Camera3DEvents = {
    DRAG_CAMERA: camera3DEventManager.createEvent(EventNames.DRAG_CAMERA),
    DRAG_CAMERA_START: camera3DEventManager.createEvent(EventNames.DRAG_CAMERA_START),
    ZOOM: camera3DEventManager.createEvent(EventNames.ZOOM),
    ROTATION_START: camera3DEventManager.createEvent(EventNames.ROTATION_START),
};
export default camera3DEventManager;
