var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SphereItem } from './sphere_item';
import { CLUSTER_NAME_REGEX } from './shared/constants';
import { isContentOverlayAction, SphereItemType } from 'shared/interfaces/planogram';
import { TextComponent } from './components/text';
import { VideoComponent } from './components/video';
import { CurveComponent } from './components/curve';
import { RectangleComponent } from './components/rectangle';
import { AppUtils } from './utils/app_utils';
import loadingProgress, { LOADING_STAGES } from './api/services/loading_progress.service';
import { Group } from 'three';
function fetchVideoAspectRatio(url) {
    const videoElm = document.createElement('video');
    videoElm.preload = 'metadata';
    videoElm.src = url;
    videoElm.load();
    return new Promise(resolve => {
        videoElm.onloadedmetadata = () => {
            videoElm.remove();
            resolve(videoElm.videoWidth / videoElm.videoHeight);
        };
    });
}
function fitFeedContent(item) {
    return __awaiter(this, void 0, void 0, function* () {
        if (item.itemData.instagram_feed !== true)
            return;
        const size = item.getSize();
        const position = item.getPosition().addScaledVector(size, 0.5);
        let newAspectRatio = 1;
        if (item.type === SphereItemType.Video)
            newAspectRatio = yield fetchVideoAspectRatio(item.data.videoUrl);
        else if (item.type === SphereItemType.Image) {
            const resolution = item.data.naturalResolution;
            newAspectRatio = resolution[0] / resolution[1];
        }
        else {
            throw new Error(`Unknown instagram feed object of type ${item.type}`);
        }
        const containerAspectRatio = size.x / size.y;
        const contentToContainer = newAspectRatio / containerAspectRatio;
        if (contentToContainer > 1)
            size.y /= contentToContainer;
        else
            size.x *= contentToContainer;
        position.addScaledVector(size, -0.5);
        item.overridePosition(position.x, position.y);
        item.overrideSize(size.x, size.y);
    });
}
export class SphereItems extends Group {
    constructor(planogram) {
        super();
        this.planogram = planogram;
        this.items = [];
        this.layers.enable(2);
        this.items = this.planogram.items.map(item => this.createSphereItem(item));
    }
    loadSphereItems(capabilities) {
        const newPickingItemsMap = new Map();
        if (this.items.length === 0)
            loadingProgress.progressStage(LOADING_STAGES.ITEM_MESHES, 1);
        const itemPromises = this.items.map(sphereItem => fitFeedContent(sphereItem)
            .then(() => sphereItem.createMesh(capabilities))
            .catch(err => {
            console.error(`Sphere item mesh creation failed: ${err}`);
        })
            .then(() => {
            loadingProgress.progressStage(LOADING_STAGES.ITEM_MESHES, this.items.length);
            if (!sphereItem.visible)
                return;
            this.add(sphereItem.object3D);
            const item = sphereItem.object3D.userData.component;
            if (item) {
                newPickingItemsMap.set(String(item.id), sphereItem.object3D);
            }
        }));
        return Promise.all(itemPromises).then(); // discard void[], thanks Typescript
    }
    dispose() {
        this.items.forEach(it => it.dispose());
    }
    createSphereItem(item) {
        switch (item.type) {
            case SphereItemType.Video:
                return new VideoComponent(item, this.planogram);
            case SphereItemType.Text:
            case SphereItemType.TextArea:
                return new TextComponent(item, this.planogram);
            case SphereItemType.Shape:
            case SphereItemType.Cluster:
                return new RectangleComponent(item, this.planogram);
            case SphereItemType.Curve:
                return new CurveComponent(item, this.planogram);
            case SphereItemType.Image:
            case SphereItemType.Product:
                // rely on material being overriden by LOD system
                return new SphereItem(item, this.planogram);
            default:
                return new SphereItem(item, this.planogram);
        }
    }
    findClusterByClusterName(clusterName) {
        // handle case with raw value as cluster name
        if (clusterName.match(CLUSTER_NAME_REGEX)) {
            clusterName = AppUtils.extractClusterName(clusterName);
        }
        const foundClusters = this.items.filter(item => {
            return item.data.clusterLink === clusterName && item.type === SphereItemType.Cluster;
        });
        if (foundClusters.length) {
            if (foundClusters.length > 1) {
                console.error('There are more than one cluster with the same name');
            }
            return foundClusters[0];
        }
    }
    findSphereItemByIdentifier(identifier) {
        return this.items.find(item => {
            var _a;
            return item.identifier === identifier || ((_a = item.id) === null || _a === void 0 ? void 0 : _a.toString()) === identifier;
        });
    }
    findSphereItemByActionIdentifier(identifier) {
        return this.items.find(item => {
            var _a;
            return item.identifier === identifier || ((_a = item.id) === null || _a === void 0 ? void 0 : _a.toString()) === identifier;
        });
    }
    findSphereItemByImageId(imageId) {
        return this.items.find(item => {
            var _a, _b, _c, _d;
            return ((_b = (_a = item.data.picture) === null || _a === void 0 ? void 0 : _a.id) === null || _b === void 0 ? void 0 : _b.toString()) === imageId ||
                ((_c = item.data.id) === null || _c === void 0 ? void 0 : _c.toString()) === imageId ||
                ((_d = item.id) === null || _d === void 0 ? void 0 : _d.toString()) === imageId;
        });
    }
    findSphereItemByContentLink(link) {
        return this.items.find(it => it.action !== undefined && isContentOverlayAction(it.action) && it.action.data.iframeLink === link);
    }
}
