import { OrthographicCamera, Vector2, Vector3 } from 'three';
import cameraConfig from 'shared/config/CameraConfig';
import planogramConfig from 'shared/config/PlanogramConfig';
import { calculateZXFlattenAngle, setWorldPosition } from 'shared/utils/GeometryUtils';
import PlanogramPoint from 'shared/utils/PlanogramPoint';
import { epsilonEq } from 'shared/utils/float-util';
import { radToDeg } from 'three/src/math/MathUtils';
// TODO: de-duplicate with editor/models/enums
var Direction;
(function (Direction) {
    Direction["LEFT"] = "left";
    Direction["RIGHT"] = "right";
    Direction["TOP"] = "top";
    Direction["BOTTOM"] = "bottom";
})(Direction || (Direction = {}));
const DISTANCE_BEFORE_UPDATING_EDGE_OBJECTS = 1000;
export const create2DCameras = (canvasSize, initialCameraPosition) => {
    const cameraX = initialCameraPosition ? initialCameraPosition.x : planogramConfig.WIDTH / 2;
    const cameraY = initialCameraPosition ? initialCameraPosition.y : 0;
    const mainCamera = new OrthographicCamera((canvasSize.x / -2) * (planogramConfig.HEIGHT / canvasSize.y), (canvasSize.x / 2) * (planogramConfig.HEIGHT / canvasSize.y), planogramConfig.HEIGHT / 2, planogramConfig.HEIGHT / -2, cameraConfig.CAMERA_2D_NEAR_PLANE, cameraConfig.CAMERA_2D_FAR_PLANE);
    mainCamera.lookAt(new Vector3(0, 0, 0));
    mainCamera.position.setX(cameraX);
    mainCamera.position.setY(cameraY);
    mainCamera.position.setZ(cameraConfig.DEFAULT_Z_POSITION);
    const primaryOverflowCamera = mainCamera.clone();
    primaryOverflowCamera.position.setX(cameraX - planogramConfig.WIDTH);
    primaryOverflowCamera.updateProjectionMatrix();
    const secondaryOverflowCamera = mainCamera.clone();
    secondaryOverflowCamera.position.setX(cameraX + planogramConfig.WIDTH);
    secondaryOverflowCamera.updateProjectionMatrix();
    mainCamera.layers.enable(1);
    primaryOverflowCamera.layers.enable(0);
    secondaryOverflowCamera.layers.enable(0);
    return { mainCamera, primaryOverflowCamera, secondaryOverflowCamera };
};
export const shouldUpdateEdgeObjects = (cameraX, previousX) => {
    if (previousX === undefined)
        return true;
    return Math.abs(previousX - cameraX) > DISTANCE_BEFORE_UPDATING_EDGE_OBJECTS;
};
/**
 * Moves objects left or right by one canvas WIDTH, moving them closer to the camera.
 *
 * This is done in order to move potentially overlaping objects from left and right edges of the canvas
 * into one camera (one render pass). Otherwise the layers/renderOrder of such overlapping items breaks,
 * as the objects are rendered in two different passes (two cameras).
 *
 * @param cameraX The position of the main camera
 */
export const shiftObjectsCloserToCamera = (objects, cameraPosition) => {
    objects.forEach(obj => {
        const worldPosition = obj.position;
        const normalizedPosition = new PlanogramPoint(worldPosition).toVectorAround(new Vector3(), cameraPosition);
        if (!epsilonEq(worldPosition.x, normalizedPosition.x, 1e-10)) {
            setWorldPosition(obj, normalizedPosition);
        }
    });
};
export const setOrthographicCameraFrustum = (camera, canvasSize, scale = new Vector2(1.0, 1.0)) => {
    camera.left = (canvasSize.x / -2) * (planogramConfig.HEIGHT / canvasSize.y) * scale.x;
    camera.right = (canvasSize.x / 2) * (planogramConfig.HEIGHT / canvasSize.y) * scale.x;
    camera.top = (planogramConfig.HEIGHT / 2) * scale.y;
    camera.bottom = (planogramConfig.HEIGHT / -2) * scale.y;
};
export const copyOrthographicCameraFrustum = (copyTo, copyFrom) => {
    copyTo.left = copyFrom.left;
    copyTo.right = copyFrom.right;
    copyTo.top = copyFrom.top;
    copyTo.bottom = copyFrom.bottom;
};
export const updateOverflowCameras = (mainCamera, primaryOverflowCamera, secondaryOverflowCamera, zoom) => {
    const cameraViewportWidth = (planogramConfig.HEIGHT * (mainCamera.right / mainCamera.top)) / zoom;
    primaryOverflowCamera.position.copy(mainCamera.position);
    primaryOverflowCamera.position.setX(mainCamera.position.x + (Math.sign(mainCamera.position.x) || 1) * -planogramConfig.WIDTH);
    copyOrthographicCameraFrustum(primaryOverflowCamera, mainCamera);
    primaryOverflowCamera.zoom = mainCamera.zoom;
    primaryOverflowCamera.updateProjectionMatrix();
    if (Math.abs(primaryOverflowCamera.position.x) + cameraViewportWidth / 2 >=
        planogramConfig.WIDTH) {
        secondaryOverflowCamera.visible = true;
        secondaryOverflowCamera.position.copy(mainCamera.position);
        secondaryOverflowCamera.position.setX(mainCamera.position.x + (Math.sign(mainCamera.position.x) || 1) * planogramConfig.WIDTH);
        copyOrthographicCameraFrustum(secondaryOverflowCamera, mainCamera);
        secondaryOverflowCamera.zoom = mainCamera.zoom;
        secondaryOverflowCamera.updateProjectionMatrix();
    }
    else {
        secondaryOverflowCamera.visible = false;
    }
};
export const getDirectionalAnimationVector = (animationDirections, speed) => {
    const newAnimationVector = new Vector2();
    animationDirections.forEach((isActive, direction) => {
        if (!isActive)
            return;
        switch (direction) {
            case Direction.LEFT:
                newAnimationVector.add(new Vector2(-1, 0));
                break;
            case Direction.RIGHT:
                newAnimationVector.add(new Vector2(1, 0));
                break;
            case Direction.TOP:
                newAnimationVector.add(new Vector2(0, 1));
                break;
            case Direction.BOTTOM:
                newAnimationVector.add(new Vector2(0, -1));
                break;
            default:
        }
    });
    return newAnimationVector.multiplyScalar(speed);
};
export const getHorizonLineOffset = (horizonLine) => planogramConfig.EQUATOR_RADIUS * horizonLine;
export const getCameraZOffset = () => planogramConfig.EQUATOR_RADIUS * cameraConfig.Z_OFFSET_FACTOR;
export const normalizeCanvasPosition = (x, y, canvasSize) => new Vector2((x / canvasSize.width) * 2 - 1, -(y / canvasSize.height) * 2 + 1);
const calc360AngleBetween = (fromVec3, toVec3) => {
    const firstAngle = calculateZXFlattenAngle(fromVec3);
    const endAngle = calculateZXFlattenAngle(toVec3);
    fromVec3.applyAxisAngle(new Vector3(0, 1, 0), firstAngle);
    toVec3.applyAxisAngle(new Vector3(0, 1, 0), endAngle);
    const flatFromPoint = new Vector2(fromVec3.x, fromVec3.y);
    const flatToPoint = new Vector2(toVec3.x, toVec3.y);
    return angleFor(flatFromPoint) - angleFor(flatToPoint);
};
const angleFor = (point) => {
    const angle = point.angle();
    if (angle > Math.PI) {
        return angle - 2 * Math.PI;
    }
    return angle;
};
export const tiltAngleBetweenIntersections = (latestIntersect, previousIntersect, cameraPosition) => {
    if (previousIntersect === undefined || latestIntersect === undefined) {
        return 0;
    }
    const camVec = cameraPosition.clone().negate();
    const startTiltPoint = previousIntersect.clone();
    const endTiltPoint = latestIntersect.clone();
    startTiltPoint.add(camVec);
    endTiltPoint.add(camVec);
    const angle = calc360AngleBetween(startTiltPoint, endTiltPoint);
    return angle;
};
export const panAngleBetweenIntersections = (latestIntersect, previousIntersect) => {
    if (previousIntersect === undefined || latestIntersect === undefined) {
        return 0;
    }
    const startPanPoint = new Vector2(previousIntersect.x, previousIntersect.z);
    const endPanPoint = new Vector2(latestIntersect.x, latestIntersect.z);
    const panAngle = endPanPoint.angle() - startPanPoint.angle();
    return panAngle;
};
export const getFullFlatteningAngle = (cameraPosition, intersectionNormal) => {
    const cameraPoint = new Vector2(cameraPosition.z, cameraPosition.y);
    const normalPoint = new Vector2(intersectionNormal.z, intersectionNormal.y);
    let angleToRotate = normalPoint.angle() - cameraPoint.angle();
    if (normalPoint.angle() > Math.PI) {
        if (cameraPoint.angle() < Math.PI) {
            angleToRotate -= Math.PI * 2;
        }
    }
    if (-angleToRotate > Math.PI * 2 ||
        (-angleToRotate > Math.PI * 1.5 && cameraPoint.angle() > Math.PI * 1.5)) {
        angleToRotate += Math.PI * 2;
    }
    return -angleToRotate;
};
export const getFractionOfFlatteningAngle = (fullAngle, zoomFraction, startFraction, endFraction) => {
    if (zoomFraction < startFraction) {
        return 0.0;
    }
    if (zoomFraction >= endFraction) {
        return fullAngle;
    }
    const a = zoomFraction - startFraction;
    const b = endFraction - startFraction;
    return (a / b) * fullAngle;
};
export const panAngleToPlanogramX = (panAngle, planogramWidth) => (-panAngle / (2 * Math.PI)) * planogramWidth;
export function fovForSize(size, aspectRatio, cameraDistance) {
    const fovForAxis = (value) => radToDeg(2 * Math.atan(value / (2 * cameraDistance)));
    return Math.max(fovForAxis(size.width / aspectRatio), fovForAxis(size.height));
}
