import { Axis } from 'shared/interfaces/Enums';
import CameraAnimation from './CameraAnimation';
import { Sigmoid } from './Sigmoid';
const MOMENTUM_SIGMOID_PARAMETERS = {
    curveMaxValue: 0.95,
    xValueOfMidpoint: -5,
    gradient: 120,
};
const MINIMUM_ANGULAR_SPEED_FOR_MOMENTUM = 0.0001;
export default class MomentumAnimation extends CameraAnimation {
    constructor(currentAngularSpeed, lastRotationCurveTime, axis) {
        super();
        this.currentAngularSpeed = currentAngularSpeed;
        this.lastRotationCurveTime = lastRotationCurveTime;
        this.axis = axis;
        this.speedCurve = new Sigmoid(MOMENTUM_SIGMOID_PARAMETERS);
    }
    update() {
        const rotation = this.calculateMomentumRotationCurve(Date.now());
        if (Math.abs(rotation) > MINIMUM_ANGULAR_SPEED_FOR_MOMENTUM) {
            if (this.axis === Axis.X) {
                return { pan: rotation };
            }
            if (this.axis === Axis.Y) {
                return { tilt: rotation };
            }
        }
        return undefined;
    }
    calculateMomentumRotationCurve(timestamp) {
        let secondsSinceRelease = (timestamp - this.lastRotationCurveTime) / 1000;
        if (secondsSinceRelease <= 0) {
            secondsSinceRelease = 1 / 60.0;
        }
        const speedScale = this.speedCurve.yValue(secondsSinceRelease);
        this.currentAngularSpeed *= speedScale;
        const rotateAngle = this.currentAngularSpeed * (secondsSinceRelease * 1000);
        this.lastRotationCurveTime = timestamp;
        return rotateAngle;
    }
}
