import { EventDispatcher } from 'three';
import { tiltAngleBetweenIntersections, panAngleBetweenIntersections, } from 'shared/utils/CameraUtils';
import MomentumAnimation from 'shared/renderingEngine/animations/MomentumAnimation';
import { Axis } from 'shared/interfaces/Enums';
import { arrayAverage } from 'shared/utils/ArrayUtils';
import RotateAnimation from 'shared/renderingEngine/animations/RotateAnimation';
import camera3DEventManager, { Camera3DEvents } from 'shared/eventManager/Camera3DEventManager';
import heatMapEventManager, { HeatMapEvents } from 'shared/eventManager/HeatmapEventManager';
import { assertDefined } from '../utils/debug';
const MAXIMUM_MOMENTUM_START_SPEED = 0.05;
const MAX_SPEED_ARRAY_LENGTH = 5;
export default class SphereCameraControls extends EventDispatcher {
    constructor(sphereCamera) {
        super();
        this.sphereCamera = sphereCamera;
        this.angularSpeeds = { [Axis.X]: [], [Axis.Y]: [] };
        this.appendAngularSpeed = (axis, newSpeedValue) => {
            if (!Number.isFinite(newSpeedValue) || Math.abs(newSpeedValue) > MAXIMUM_MOMENTUM_START_SPEED)
                return;
            const speedArray = this.angularSpeeds[axis];
            if (speedArray.length >= MAX_SPEED_ARRAY_LENGTH) {
                speedArray.shift();
            }
            speedArray.push(newSpeedValue);
        };
        this.animationChain = new Set();
        this.rotateAnimation = new RotateAnimation(this.sphereCamera);
    }
    move(latestIntersect, previousIntersect) {
        const { panAngle } = this.moveBetweenIntersections(latestIntersect, previousIntersect);
        if (panAngle > 0) {
            this.heatMapMovementDirection = 'left';
        }
        else {
            this.heatMapMovementDirection = 'right';
        }
        camera3DEventManager.trigger(Camera3DEvents.DRAG_CAMERA);
    }
    moveBetweenIntersections(latestIntersect, previousIntersect) {
        const tiltAngle = tiltAngleBetweenIntersections(latestIntersect, previousIntersect, this.sphereCamera.camera.position);
        const panAngle = panAngleBetweenIntersections(latestIntersect, previousIntersect);
        this.rotateCameras(panAngle, tiltAngle);
        return { panAngle, tiltAngle };
    }
    rotateCameras(panAngle, tiltAngle) {
        this.sphereCamera.tiltAndPan({ tilt: tiltAngle, pan: panAngle });
        this.dispatchEvent({ type: 'CameraUpdate' });
        this.updateAngularSpeeds(panAngle, tiltAngle);
    }
    endCameraDrag() {
        this.decelerateWithMomentumAnimation();
        heatMapEventManager.send(HeatMapEvents.MOVE, {
            zoomFraction: this.sphereCamera.zoomFraction,
            direction: this.heatMapMovementDirection,
        });
    }
    zoomIntoPoint(screenPoint, zoomFactor) {
        const initialIntersection = this.sphereCamera.getMouseIntersection(screenPoint.x, screenPoint.y);
        const oldZoomFraction = this.sphereCamera.zoomFraction;
        this.sphereCamera.zoomBy(zoomFactor);
        this.dispatchEvent({ type: 'CameraUpdate' });
        const newIntersection = this.sphereCamera.getMouseIntersection(screenPoint.x, screenPoint.y);
        if (!initialIntersection || !newIntersection)
            return;
        this.moveBetweenIntersections(newIntersection, initialIntersection);
        const newZoomFraction = this.sphereCamera.zoomFraction;
        heatMapEventManager.send(HeatMapEvents.ZOOM, {
            point: screenPoint,
            oldZoomFraction,
            newZoomFraction,
        });
        camera3DEventManager.send(Camera3DEvents.ZOOM, this.sphereCamera.zoom);
    }
    zoomBy(zoomFactor) {
        this.sphereCamera.zoomBy(zoomFactor);
        this.dispatchEvent({ type: 'CameraUpdate' });
        camera3DEventManager.send(Camera3DEvents.ZOOM, this.sphereCamera.zoom);
    }
    zoomTo(fov) {
        this.sphereCamera.zoomTo(fov);
        this.dispatchEvent({ type: 'CameraUpdate' });
    }
    decelerateWithMomentumAnimation() {
        if (this.speedSavedAt === undefined)
            return;
        this.addAnimation(new MomentumAnimation(arrayAverage(this.angularSpeeds[Axis.X]), this.speedSavedAt, Axis.X));
        this.addAnimation(new MomentumAnimation(arrayAverage(this.angularSpeeds[Axis.Y]), this.speedSavedAt, Axis.Y));
        this.angularSpeeds[Axis.X] = [];
        this.angularSpeeds[Axis.Y] = [];
    }
    startRotationAnimation(direction) {
        this.rotateAnimation.addRotation(direction, this.sphereCamera.zoomFraction);
        if (this.rotateAnimation.isNotRotating()) {
            this.stopRotatitionAnimation();
        }
        else {
            this.addAnimation(this.rotateAnimation);
        }
        camera3DEventManager.send(Camera3DEvents.ROTATION_START, { direction });
    }
    stopRotatitionAnimation() {
        this.rotateAnimation.clearRotation();
        this.removeAnimation(this.rotateAnimation);
    }
    addAnimation(animation) {
        this.animationChain.add(animation);
    }
    removeAnimation(animation) {
        this.animationChain.delete(animation);
    }
    clearAnimations() {
        this.animationChain.clear();
    }
    updateAngularSpeeds(panAngle, tiltAngle) {
        if (this.speedSavedAt !== undefined) {
            const timePeriod = Date.now() - this.speedSavedAt;
            this.appendAngularSpeed(Axis.X, panAngle / timePeriod);
            this.appendAngularSpeed(Axis.Y, tiltAngle / timePeriod);
        }
        this.speedSavedAt = Date.now();
    }
    prepareToRender() {
        this.updateAnimations();
    }
    updateAnimations() {
        this.animationChain.forEach(animation => {
            var _a, _b;
            const cameraAdjustment = animation.getAdjustment();
            if (!cameraAdjustment) {
                this.removeAnimation(animation);
                return;
            }
            if (cameraAdjustment.fov !== undefined) {
                this.zoomTo(cameraAdjustment.fov);
                if (cameraAdjustment.targetPoint) {
                    const currentIntersection = this.sphereCamera.getIntersection();
                    assertDefined(currentIntersection, 'Camera not looking at the sphere');
                    this.moveBetweenIntersections(currentIntersection, cameraAdjustment.targetPoint);
                }
                return;
            }
            if (cameraAdjustment.pan !== undefined || cameraAdjustment.tilt !== undefined) {
                this.rotateCameras((_a = cameraAdjustment.pan) !== null && _a !== void 0 ? _a : 0, (_b = cameraAdjustment.tilt) !== null && _b !== void 0 ? _b : 0);
            }
        });
    }
}
