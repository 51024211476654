import PlanogramPoint from 'shared/utils/PlanogramPoint';
import { debugFloatPrameter, debugIntParameter, debugLog, hasDebugFlag } from 'shared/utils/debug';
import ImageRegistry from './ImageRegistry';
import { TileMap } from './TileMap';
import LodMaterial from './LodMaterial';
import PhysicalTextures from './PhysicalTextures';
import TileLoader from './TileLoader';
import { TextureCache } from './TextureCache';
import LodDebug from './LodDebug';
const defaultParameters = {
    tileMapResolution: 2048,
    physicalTextureCount: 8,
    physicalTextureResolution: 4096,
    simultaneousTilesLoading: 8,
    targetPixelRatio: 1.1,
    unloadedTileBias: 5,
    cdnUrl: '',
    blacklistDuration: 30 * 1000,
    // TODO: setting this parameter below 4 produces renders of incorrect tiles, e. g. on https://jack-wood-guitar.cozmos.com/en/shredders_university_audio
    // the tile map writes to wait for physical texture writes, but somehow it still desyncs. Does it need to wait an extra frame?
    frameUploadLimit: 5,
};
function fallbackLodParameters(input, gl) {
    const defaultedParameters = Object.assign(Object.assign({}, defaultParameters), input);
    const parameters = Object.assign(Object.assign({}, defaultedParameters), { tileMapResolution: debugIntParameter('TILEMAP_RESOLUTION', defaultedParameters.tileMapResolution), physicalTextureCount: debugIntParameter('PHYSICAL_TEXTURE_COUNT', defaultedParameters.physicalTextureCount), physicalTextureResolution: debugIntParameter('PHYSICAL_TEXTURE_RESOLUTION', defaultedParameters.physicalTextureResolution), simultaneousTilesLoading: debugIntParameter('SIMULTANEOUS_TILES_LOADING', defaultedParameters.simultaneousTilesLoading), targetPixelRatio: debugFloatPrameter('TARGET_PIXEL_RATIO', defaultedParameters.targetPixelRatio), unloadedTileBias: debugFloatPrameter('UNLOADED_TILE_BIAS', defaultedParameters.unloadedTileBias), blacklistDuration: debugIntParameter('BLACKLIST_DURATION', defaultedParameters.blacklistDuration), frameUploadLimit: debugIntParameter('FRAME_UPLOAD_LIMIT', defaultedParameters.frameUploadLimit) });
    parameters.physicalTextureResolution = Math.min(gl.capabilities.maxTextureSize, parameters.physicalTextureResolution);
    parameters.tileMapResolution = Math.min(gl.capabilities.maxTextureSize, parameters.tileMapResolution);
    parameters.physicalTextureCount = Math.min(gl.capabilities.maxTextures - 1, parameters.physicalTextureCount);
    return parameters;
}
export default class LodProvider {
    constructor(renderer, parameterOverrides) {
        this.materials = new Map();
        this.focusPoint = new PlanogramPoint();
        this.pixelRatio = 0;
        this.loaded = false;
        this.loadedListeners = new Set();
        const parameters = fallbackLodParameters(parameterOverrides, renderer);
        this.parameters = parameters;
        this.tileMap = new TileMap(parameters.tileMapResolution, renderer);
        this.physicalTextures = new PhysicalTextures(parameters.physicalTextureCount, parameters.physicalTextureResolution, renderer, parameters.frameUploadLimit);
        this.textureCache = new TextureCache();
        this.tileLoader = new TileLoader(this.tileMap, this.textureCache, this.physicalTextures, parameters.cdnUrl, parameters.simultaneousTilesLoading, parameters.unloadedTileBias, parameters.blacklistDuration, parameters.targetPixelRatio);
        this.imageRegistry = new ImageRegistry(this.tileMap, this.tileLoader);
        this.imageRegistry.onImageReady((id) => {
            var _a;
            const material = (_a = this.materials.get(id)) !== null && _a !== void 0 ? _a : this.makeMaterial();
            this.materials.set(id, material);
            material.setItemUniforms(this.imageRegistry.getImageUniforms(id));
        });
        if (hasDebugFlag('physicalTextures'))
            this.debug = new LodDebug(this.physicalTextures.textures);
    }
    update() {
        var _a;
        const noWork = [
            this.tileMap.update(),
            this.physicalTextures.update(),
            this.tileLoader.update(this.focusPoint, this.pixelRatio),
        ].every(it => it);
        if (noWork && !this.loaded && !this.imageRegistry.isLoading()) {
            this.loadedListeners.forEach(it => it());
            debugLog(`Lod Provider finished pre-loading`);
            this.loaded = true;
        }
        (_a = this.debug) === null || _a === void 0 ? void 0 : _a.update();
    }
    updateImageList(images, lodLoader) {
        this.imageRegistry.updateImages(images, lodLoader);
    }
    updateCamera(position, pixelsToPlanogramRatio) {
        this.focusPoint.copy(position);
        this.pixelRatio = pixelsToPlanogramRatio;
    }
    makeMaterial() {
        return new LodMaterial(this.tileMap, this.physicalTextures.textures, this.parameters.physicalTextureResolution, hasDebugFlag('lodColors'));
    }
    getMaterial(id) {
        let material = this.materials.get(id);
        if (material === undefined) {
            material = this.makeMaterial();
            this.materials.set(id, material);
        }
        return material;
    }
    addLoadedListener(listener) {
        this.loadedListeners.add(listener);
    }
    removeLoadedListener(listener) {
        this.loadedListeners.delete(listener);
    }
    dispose() {
        var _a;
        (_a = this.debug) === null || _a === void 0 ? void 0 : _a.dispose();
        this.tileLoader.dispose();
        this.physicalTextures.dispose();
        this.textureCache.dispose();
        this.tileMap.dispose();
    }
}
